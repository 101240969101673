// user.js

const state = {
    //デフォルトのユーザー名はランダムなアルファベットと数字８文字
    username: "NewUser",
    iconId: 1,
    userId: "99999",
    inRoomId:""
};

// Getters
const getters = {
    getUsername: state => state.username,
    getIconId: state => state.iconId,
    getUserId: state => state.userId,
    getInRoomId: state => state.inRoomId
};

// Mutations
const mutations = {
    setUsername: (state, username) => {
        state.username = username;
    },
    setIconId: (state, iconId) => {
        state.iconId = iconId;
    },
    setUserId: (state, userId) => {
        state.userId = userId;
    }
    ,
    setInRoomId: (state, inRoomId) => {
        state.inRoomId = inRoomId;
    }
};

// Actions
const actions = {
    updateUsername: ({ commit }, username) => {
        commit('setUsername', username);
        //localStorageにユーザー名を保存
        localStorage.setItem('userName', username);
    },
    updateIconId: ({ commit }, iconId) => {
        commit('setIconId', iconId);
        //localStorageにアイコンIDを保存
        localStorage.setItem('iconId', iconId);
    },
    updateUserId: ({ commit }, userId) => {
        commit('setUserId', userId);
    },
    updateInRoomId: ({ commit }, inRoomId) => {
        commit('setInRoomId', inRoomId);
    },
    generateRandomString({ commit }, payload) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < payload.length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        commit('setUserId', result);
        return result;
    },
};

// Export the user store module
export default {
    state,
    getters,
    mutations,
    actions
};