import { createRouter, createWebHistory } from "vue-router";
import PrivateChat from "../views/PrivateChat.vue";
import Login from "../views/Login.vue";
import Icon from "../views/Icon.vue";
import RoomList from "../views/RoomList.vue";
import CreateRoom from "../views/CreateRoom.vue";

const routes = [
  { path: "/room/:roomId", name: "Room", component: PrivateChat, props: true },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: '/icon-setting',
    name: 'IconSetting',
    component: Icon
  },
  {
    path: '/room-list',
    name: 'RoomList',
    component: RoomList
  },
  {
    path: '/create-room',
    name: 'CreateRoom',
    component: CreateRoom
  },
  { path: "/:pathMatch(.*)*", redirect: "/login" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



export default router;
