import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { createStore } from 'vuex';
import user from './store/user';
import roomDetail from './store/roomDetail';
import roomList from './store/roomList';
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

// Required for side-effects
require("firebase/firestore");
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCKkAxE9VvVymeS354hv4BwbrhiyO7p8Ic",
  authDomain: "kotoniwa-b4882.firebaseapp.com",
  projectId: "kotoniwa-b4882",
  storageBucket: "kotoniwa-b4882.appspot.com",
  messagingSenderId: "963343335008",
  appId: "1:963343335008:web:012a072ffe3a44b1bc0d05",
  measurementId: "G-X0NDEJJ5YD"
};
const store = createStore({
  modules: {
    user,
    roomDetail,
    roomList,
  }
});
// Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // Initialize the Vertex AI service
  const vertexAI = getVertexAI(app);

  // Initialize the generative model with a model that supports your use case
  // Gemini 1.5 Pro is versatile and can accept both text-only or multimodal prompt inputs
  const kotoniwaGenerativeModel  = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash-preview-0514",  
  generationConfig: {
    'maxOutputTokens': 1000,
    'temperature': 1,
    'topP': 0.95,
    'topK': 40,
  }, 
  safetySettings: [
    {
      'category': 'HARM_CATEGORY_HATE_SPEECH',
      'threshold': 'BLOCK_ONLY_HIGH',
    },
    {
      'category': 'HARM_CATEGORY_DANGEROUS_CONTENT',
      'threshold': 'BLOCK_ONLY_HIGH',
    },
    {
      'category': 'HARM_CATEGORY_SEXUALLY_EXPLICIT',
      'threshold': 'BLOCK_MEDIUM_AND_ABOVE',
    },
    {
      'category': 'HARM_CATEGORY_HARASSMENT',
      'threshold': 'BLOCK_MEDIUM_AND_ABOVE',
    }
  ],
  systemInstruction: {
    parts: [{"text": `あなたはチャットの優秀なオペレータです、質問や依頼の意図をくみとって100字以内で簡潔に回答してください`}]
  },
}); 

  // Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
// make db globally accessible
window.db=db
window.kotoniwaGenerativeModel=kotoniwaGenerativeModel

createApp(App).use(store).use(router).mount("#app");
