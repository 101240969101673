<template>
  <div class="room-container" :style="{ fontSize: `${selectedFontSize}px` }">
    
  <!-- 退室ボタン -->
  <div class="header" style="height: 32px;min-height: 32px;">
    <div class="header-group">
    <button @click="toggleMenu" class="menu-button">☰</button> <!-- Toggle Menu Button -->
      <div v-if="isMenuVisible"  class="dropdown-menu">
        <ul>
          <li @click="checkRoomInfo">部屋情報を確認</li>
          <!-- roomDetail.tyoeが2でなくroomDetail.created_by_id == userIdの場合のみ表示 -->
          <li v-if ="roomDetail.created_by_id === userId && roomDetail.state == 1" @click="changeParticipantNumber">人数変更</li>
          <li v-if ="roomDetail.type !== 2 && roomDetail.created_by_id === userId && roomDetail.state == 1" @click="changeWolfNumber">狼人数変更</li>
          <li v-if ="roomDetail.type !== 2 && roomDetail.created_by_id === userId && !!roomDetail.custom_topic" @click="changeTopics">お題変更</li>
          <li @click="announceOnX">Xで告知</li>
        </ul>
      </div>
      </div>
      <div v-if="timerActive && roomDetail.state === 2" class="timer-controls">
        <button v-if ="roomDetail.created_by_id === userId" @click="decreaseTimer" class="timer-button">-</button>
        <div class="timer-display">{{ displayTime }}</div>
        <button v-if ="roomDetail.created_by_id === userId" @click="increaseTimer" class="timer-button">+</button>
      </div>
      <div class="header-group">
      <button v-if="roomDetail.state === 3" @click="toggleResultsModal" class="results-button">ゲーム結果を見る</button>
      <button v-if="roomDetail.in_user_count === roomDetail.limit_member && isUserInRoom && roomDetail.created_by_id === userId && roomDetail.state == 1" @click="startGame" class="start-button">スタート</button>
      <button v-if="roomDetail.state == 2 && isUserInRoom" @click="viewTopics" class="topic-button">お題確認</button>
    <select v-model="selectedFontSize" class="font-size-selector" @change="changeFontSize">
        <option v-for="(label, size) in fontSizeMap" :key="size" :value="size">
          {{ label }}
        </option>
      </select>
  <!-- 入室または退室ボタンの条件付き表示 -->
  <!-- roomUsersの中に自分のuserIdが含まれていない場合、入室ボタンを表示 -->
      <button v-if="!isUserInRoom && roomDetail.in_user_count < roomDetail.limit_member && roomDetail.state != 0" @click="joinRoom" class="login-button">入室</button>
      <button @click="logout" class="logout-button">退室</button>
    </div>
  </div>
  <!-- <div v-if="isHost" id="youtube-player"></div> -->
    <div class="mesgs">
      <div class="msg_history" ref="msgHistory" :style="{ height: roomDetail.state === 0 ? `calc(100svh - ${textareaHeight + 50}px)` : `calc(100svh - ${textareaHeight + 125}px)`}" @scroll="checkScrollPosition">
        <div class="scroll-buttons">
          <button v-if="!isScrolledToTop" @click="scrollTop" class="scroll-button top">&#9650;</button>
          <button v-if="!isScrolledToBottom" @click="scrollBottomAbsolute" class="scroll-button bottom">&#9660;</button>
        </div>
        <div class="incoming_msg" v-for="message in messages" :key="message.id">
          <template v-if="!message.isPrivate || message.recipientId === userId || message.authorId === userId || roomDetail.state === 3 || roomDetail.created_by_id === userId">
          <div v-if="message.authorId === userId && !isMuted(message.authorId)" class="sent_msg">
            <div v-if="message.authorName && message.message" class="received_withd_msg">
              <!-- message.authorId == roomDetail.created_by_idの場合文字列GMの後にauthorNameを緑で表示、それ以外の場合は黒で表示 -->
              <div v-if="message.authorId === roomDetail.created_by_id && !!roomDetail.custom_topic" class="sent_author_name" style="color: green">{{ getUserRoomUserId(message.authorId) }}GM {{ message.authorName }} <span v-if="message.isPrivate" class="private-indicator"> → {{ getUserRoomUserId(message.recipientId) }}{{ getUserName(message.recipientId) }}</span></div>
              <div v-else class="sent_author_name">{{ getUserRoomUserId(message.authorId) }}{{ message.authorName }} <span v-if="message.isPrivate"> → {{ getUserRoomUserId(message.recipientId) }}{{ getUserName(message.recipientId) }}</span></div>
              <p class="msg-text" :style="message.isPrivate ? privateMessageStyle : {}">{{ message.message }}
                <span v-if="roomDetail.state !== 0" class="time_date"> {{ message.createdAtTime }} </span>
                <!-- timestamp型のcreatedAtをyyyy/MM/dd HH:mm形式に変換して表示 -->
                <span v-else class="time_date"> {{ message.createdAt.toDate().toLocaleString('ja-JP') }} </span>
              </p>
            </div>
            <div class="sent_msg_img">
              <img :src="getIcon(message.authorIconId)" alt="user" />
            </div>
          </div>
          <!-- message.authorIdがedcrfvtgboius.idに含まれない場合 -->
          <div v-else-if="(!edcrfvtgboius || !edcrfvtgboius.some(item => item.recId === message.authorId)) && !isMuted(message.authorId)" class="received_msg">
            <div class="received_msg_img">
              <img :src="getIcon(message.authorIconId)" alt="other" />
            </div>
            <div class="received_withd_msg">
              <!-- messageの上の行にauthorNameを背景無しで表示 -->
              <div v-if="message.authorId === roomDetail.created_by_id && !!roomDetail.custom_topic" class="received_author_name" style="color: green">{{ getUserRoomUserId(message.authorId) }}GM {{ message.authorName }} <span v-if="message.isPrivate"> → {{ getUserRoomUserId(message.recipientId) }}{{ getUserName(message.recipientId) }}</span></div>
              <div v-else class="received_author_name">{{ getUserRoomUserId(message.authorId) }}{{ message.authorName }} <span v-if="message.isPrivate"> → {{ getUserRoomUserId(message.recipientId) }}{{ getUserName(message.recipientId) }}</span></div>
              <!-- messageの後に少し離してcreatedAtを白文字で小さいフォントで表示 -->
              <!-- message内に(secret)が含まれている場合、背景色赤の枠線は破線で表示 messageからは(secret)は除外する-->
              <!-- <p v-if="message.message.includes('(secret)')" class="msg-text" style="border: 1px dashed red;background-color: lightcoral">{{ message.message.replace('(secret)', '') }}<span class="time_date"> {{ message.createdAtTime }} </span></p> -->
              <p class="msg-text" :style="message.isPrivate ? privateMessageStyle : {}" :class="{ 'message-system': message.authorId === 'system' }">{{ message.message }}
                <span v-if="roomDetail.state !== 0" class="time_date"> {{ message.createdAtTime }} </span>
                <span v-else class="time_date"> {{ message.createdAt.toDate().toLocaleString('ja-JP') }} </span>
              </p>
            </div>
          </div>
          </template>
        </div>
      </div>
      <!-- ユーザー表示エリアの追加 -->
    <div v-if="roomDetail.state != 0" class="user-list">
      <div class="user-entry" v-for="user in roomUsers" :key="user.authorId" @click="toggleMute(user)">
        <span class="user-name">{{ user.roomUserId }}.{{ user.authorName &&user.authorName.length > 2 ? user.authorName.substring(0, 2) + '..' : user.authorName }}</span>
        <img :src="getIcon(user.authorIconId)" alt="user icon" class="user-icon" :class="{ muted: isMuted(user.authorId) }"/>
        <!-- <span class="user-name">{{ user.roomUserId }}.{{ user.authorName.length > 5 ? user.authorName.substring(0, 5) + '..' : user.authorName }}</span> -->
      </div>
    </div>
      <div v-if="isUserInRoom || roomDetail.state == 0" class="type_msg">
        <div class="input_msg_write">
          <!-- Conditionally render the dropdown for selecting message recipient -->
        <select v-if="roomDetail.type === 3 && roomDetail.use_target_message == 'true' "
                v-model="selectedRecipient"
                class="recipient-selector">
          <option value="all">全体</option>
          <option v-for="user in filteredUsers" :key="user.authorId" :value="user.authorId">
            {{ user.roomUserId }}.{{ user.authorName && user.authorName.length > 2 ? user.authorName.substring(0, 2) + '..' : user.authorName }}
          </option>
          <option v-if="roomDetail.created_by_id !== userId" value="gm">GM</option>
        </select>
          <!-- テキストエリアで複数行入力を可能に -->
          <textarea
          autocomplete="off"
        v-model="message"
        class="write_msg"
        placeholder="ここに入力"
        ref="autoResizeTextarea"
        @input="onTextareaInput"
        rows="1"
        id="room_msg_input"
      ></textarea>
          <button class="msg_send_btn" type="button" @click="saveMessage">
            <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h3>部屋情報</h3>
      <div>
         <!-- typeの値に応じて表示を変更1ならワードウルフ、2なら雑談、3ならその他 -->
        <p><strong>種類:</strong> {{ roomDetail.type === 1 ? 'ワードウルフ' : roomDetail.type === 2 ? '雑談' : roomDetail.type === 3 ? 'その他' : '常設' }}</p>
        <p><strong>名前:</strong> {{ roomDetail.name }}</p>
        <p><strong>詳細:</strong> {{ roomDetail.detail }}</p>
        <p><strong>制限時間:</strong> {{ roomDetail.limit_minute }}</p>
        <p><strong>人数:</strong> {{ roomDetail.limit_member }}人</p>
        <p><strong>狼の数:</strong> {{ roomDetail.wolf_count }}</p>
        <p><strong>公開設定:</strong> {{ roomDetail.is_public == "public" ? '公開' : '非公開' }}</p>

      </div>
    </div>
  </div>
  <div v-if="showParticipantModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeParticipantModal">&times;</span>
      <h3>部屋人数変更</h3>
      <div>
        <input type="number" v-model="newLimitMember" :min="roomDetail.in_user_count" />
        <button @click="updateLimitMember">更新</button>
      </div>
    </div>
  </div>
  <div v-if="showWolfModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeWolfModal">&times;</span>
      <h3>狼の人数変更</h3>
      <form>
        <div class="form-group row">
          <label for="wolf-count" class="col-sm-4 col-form-label">狼の人数:</label>
          <div class="col-sm-8">
            <select id="wolf-count" v-model="newWolfCount" class="form-control">
              <option value="0">0</option>
              <option v-for="n in getMaxWolves(roomDetail.limit_member)" :value="n" :key="n">{{ n }}</option>
            </select>
          </div>
        </div>
        <button type="button" @click="saveWolfCount" class="btn btn-primary">更新</button>
      </form>
    </div>
  </div>
  <div v-if="showTopicModal" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeTopicModal">&times;</span>
      <h3>お題の変更</h3>
      <form>
        <div class="form-group row">
          <label for="village-topic" class="col-sm-4 col-form-label">村側のお題:</label>
          <div class="col-sm-8">
            <textarea id="village-topic" v-model="villageTopic" class="topics-textarea"
                      placeholder="村側のお題を入力してください" rows="3"></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label for="werewolf-topic" class="col-sm-4 col-form-label">狼側のお題:</label>
          <div class="col-sm-8">
            <textarea id="werewolf-topic" v-model="werewolfTopic" class="topics-textarea"
                      placeholder="狼側のお題を入力してください" rows="3"></textarea>
          </div>
        </div>
        <button type="button" @click="saveTopics" class="btn btn-primary">更新</button>
      </form>
    </div>
  </div>
<!-- お題確認モーダル -->
<div v-if="showTopicViewModal" class="modal">
  <div class="modal-content">
    <span class="close" @click="closeTopicViewModal">&times;</span>
    <h3>あなたのお題</h3>
    <div v-if="roomDetail.created_by_id === userId && !!roomDetail.custom_topic">
      <p><strong>村のお題:</strong> {{ roomDetail.village_topic }}</p>
      <p><strong>狼のお題:</strong> {{ roomDetail.werewolf_topic }}</p>
      <div>
        <h4>狼のリスト:</h4>
        <ul>
          <li v-for="user in roomUsers.filter(user => user.team==1)" :key="user.roomUserId">
            {{ user.roomUserId }}. {{ user.authorName }}
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <p class="topic-text">{{ userTopic }}</p>
    </div>
  </div>
</div>
<!-- 投票モーダル -->
<div v-if="showVoteModal && isUserInRoom" class="voting-modal">
  <div class="voting-modal-content">
    <h3>狼だと思うユーザーを投票してください (残り時間: {{ voteTimer }}秒)</h3>
    <select v-model="selectedVote" class="voting-select">
      <!-- 選択無しのオプションを追加 -->
      <option value="">選択なし</option>
      <!-- is_ownerがfalseのユーザーのみ表示 -->
      <option v-for="user in roomUsers.filter(user => !user.is_owner)" :value="user.roomUserId" :key="user.roomUserId">
        {{ user.roomUserId }}.{{ user.authorName }}
      </option>
    </select>
    <button @click="submitVote" class="voting-button">投票する</button>
  </div>
</div>
<!-- ゲーム結果モーダル -->
<div v-if="showResultsModal" class="results-modal">
  <div class="results-modal-content">
    <span v-if="allVotesCast" class="close-modal" @click="closeResultsModal">&times;</span>
    <h3 v-if="allVotesCast">ゲーム結果 - {{winningTeam()}}</h3>
    <h3 v-else>投票中...</h3>
    <div>
      <!-- 村お題は文字色緑色で表示 -->
      <h4 class="topic-header" style="color:green">村お題</h4>
      <p class="topic-content" style="color:green">{{ roomDetail.village_topic }}</p>
      <h4 class="topic-header" style="color:red">狼お題</h4>
      <p class="topic-content" style="color:red">{{ roomDetail.werewolf_topic }}</p>
      <ul class="vote-results-list">
        <li v-for="user in roomUsers" :key="user.authorId" class="vote-result-item">
          <template v-if="user.is_owner">
            <span class="vote-result-owner">{{ user.roomUserId }}.{{ user.authorName }} : GM</span>
          </template>
          <template v-else>
            <span :class="user.team === 0 ? 'team-villager' : 'team-werewolf'" v-if="user.vote === 0">
              {{ user.roomUserId }}.{{ user.authorName }} : 投票中
            </span>
            <span :class="user.team === 0 ? 'team-villager' : 'team-werewolf'" v-else-if="user.vote === ''">
              {{ user.roomUserId }}.{{ user.authorName }} : 投票なし
            </span>
            <span :class="user.team === 0 ? 'team-villager' : 'team-werewolf'" v-else>
              {{ user.roomUserId }}.{{ user.authorName }} →
              <span :class="findVoteRecipient(user.vote).team === 0 ? 'team-villager' : 'team-werewolf'">
                {{ findVoteRecipient(user.vote).roomUserId }}.{{ findVoteRecipient(user.vote).authorName }}
              </span>
            </span>
          </template>
        </li>
      </ul>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted, watch, nextTick, onUnmounted,props } from "vue";
import { computed } from 'vue';
import { IconUrlConst } from '../consts/iconsConst';
import { useRouter } from 'vue-router';
import { doc,updateDoc,onSnapshot,getFirestore,collection,serverTimestamp, Timestamp } from "firebase/firestore";

export default {
  props: {
    roomId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const timer = ref(null);
    const timerActive = ref(false);
    const timeRemaining = ref(0);
    const serverStartTime = ref(null);
    const serverEndTime = ref(null);
    const message = ref('');
    const authUser = ref({});
    const router = useRouter();
    const textareaHeight = ref(40); // 初期の高さ
    const autoResizeTextarea = ref(null); // autoResizeTextareaを初期化
    const fontSizeMap = {
      10: "超極小", // Extra Small
      12: "極小", // Extra Small
      13: "小", // Small
      14: "中", // Medium
      15: "大", // Large
      16: "極大", // Extra Large
      18: "超極大", // Extra Extra Large
      22: "巨大", // Extra Extra Large
    };
    const selectedFontSize = ref(store.getters.getFontSize); // Default font size
    // let player = null;
    const isHost = computed(() => store.getters.getUserId === store.getters.getRoomDetail.created_by_id);
    

    // 状態のメッセージをストアから取得するためのゲッター
    const messages = computed(() => store.getters.allMessages);
    const userId = computed(() => store.getters.getUserId);
    const edcrfvtgboius = computed(() => store.getters.getedcrfvtgboiu);
    const constRoomId = computed(() => store.getters.getRoomId);
    const roomDetail = computed(() => store.getters.getRoomDetail);
    const roomUsers = computed(() => store.getters.getUsers);
    const inRoomId = computed(() => store.getters.getInRoomId);
    const isMenuVisible = ref(false);
    const showModal = ref(false);
    const showParticipantModal = ref(false);
    const newLimitMember = ref('');
    const showWolfModal = ref(false);
    const newWolfCount = ref('');
    const showTopicModal = ref(false);
    const villageTopic = ref('');
    const werewolfTopic = ref('');
    const showTopicViewModal = ref(false);
    const userTopic = ref("");
    const showVoteModal = ref(false);
    const showResultsModal = ref(false);
    const voteTimer = ref(0);
    const selectedVote = ref("");
    const voteTimeout = ref(null);
    const votes = ref([]); // ここに投票結果を保存
    const msgHistory = ref(null);
    const isScrolledToBottom = ref(true);
    const isScrolledToTop = ref(true);
    const muteStates = ref({});
    const selectedRecipient = ref("all");
    const privateMessageStyle = {
      backgroundColor: 'lavender', // Example: a gentle background color
      border: '1px dashed #4A4A4A', // Dashed border to make it visually distinct
      color: '#333', // Darker text for better contrast
    };
    const filteredUsers = computed(() => {
      if (roomDetail.value.created_by_id === userId.value) {
        return roomUsers.value.filter(user => user.authorId !== userId.value);
      } else {
        return [];
      }
    });
    // const videoRef = db.collection('rooms').doc(roomId.value);
    // ログインページに戻る
    const logout = () => {
      if (roomDetail.value.state !== 0 && isUserInRoom.value){
      store.dispatch('updateInRoomId', "");
      store.dispatch('leaveRoom', {authorId:store.getters.getUserId,authorName:store.getters.getUsername});
      }
      router.push('/room-list'); 
    };
    // メッセージの初期化とクリーンアップ処理
    const initMessages = () => {
      store.dispatch('initMessages');
    };
    const fetchEdcrfvtgboiu = () => {
      store.dispatch('fetchEdcrfvtgboiu');
    };
    const isUserInRoom = computed(() => {
      return roomUsers.value.some(user => user.authorId === userId.value);
    });
    const changeFontSize = () => {
      // Use a root-level CSS variable to control font size
      document.documentElement.style.setProperty("--base-font-size", `${selectedFontSize.value}px`);
      store.dispatch("updateFontSize", selectedFontSize.value);
    };
    function getIcon(iconId) {
      return IconUrlConst[iconId] || '';
    }
    const displayTime = computed(() => {
      const minutes = Math.floor(timeRemaining.value / 60);
      const seconds = timeRemaining.value % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    });
    const joinRoom = () => {
      store.dispatch('updateInRoomId', props.roomId);
      store.dispatch('joinRoom', {authorId:store.getters.getUserId,authorName:store.getters.getUsername,authorIconId:store.getters.getIconId});
    };
    async function saveMessage() {
      // 150文字を超えたら送信を中止
      if (message.value.length > 150) {
        alert("150文字以内で入力してください"); // 警告を表示
        return; // 送信を中止
      }
      if (message.value.trim() !== "") {
        const target = selectedRecipient.value;
        const isPrivate = target !== 'all';
        await store.dispatch('saveMessage', {
          message: message.value
          , authorId: store.getters.getUserId
          , authorName: store.getters.getUsername
          , authorIconId: store.getters.getIconId
          , isPrivate: isPrivate
          , recipientId: target === 'gm' ? roomDetail.value.created_by_id : target
        });
        message.value = ''; // 入力をクリア
        resizeReset();
        scrollBottomAbsolute();
      }
    }
    const onTextareaInput = () => {
      const textarea = autoResizeTextarea.value;
      const currentMessage = textarea.value; // 現在の入力内容

      // 最大行数の制限をチェック (5行)
      const lineCount = currentMessage.split('\n').length;
      if (lineCount > 5) {
        const lines = currentMessage.split('\n').slice(0, 5); // 5行目までを抽出
        textarea.value = lines.join('\n'); // テキストエリアの内容を更新
      }

      message.value = textarea.value; // 制限されたメッセージを更新
      autoResize(); // テキストエリアの高さを更新
      scrollBottom(); // メッセージの最下部にスクロール
    }
    const autoResize = () => {
      const textarea = autoResizeTextarea.value;
      if (textarea) {
        textarea.style.height = "auto"; // 高さをリセット
        const newHeight = textarea.scrollHeight; // 内容に応じて高さを取得
        textarea.style.height = `${newHeight}px`; // 新しい高さを設定
        textareaHeight.value = newHeight; // 変数に新しい高さを保存
      }
    }
    const resizeReset = () => {
      const textarea = autoResizeTextarea.value;
      if (textarea) {
        textarea.style.height = "auto"; // 高さをリセット
        textarea.style.height = `40px`; // 新しい高さを設定
        textareaHeight.value = 40; // 変数に新しい高さを保存
      }
    }
    const werewolves = () =>{
    return roomUsers.value.filter(user => user.team == 1);
    }
    function scrollBottom() {
      const box = document.querySelector(".msg_history");
      // 現在のスクロール位置 + コンテナの可視高さ が 全体のスクロール高さよりも少ない（または等しい）場合にスクロールを実行
      if (isScrolledToBottom.value) {
        box.scrollTop = box.scrollHeight;
       }
    }
    function scrollBottomAbsolute() {
      const box = document.querySelector(".msg_history");
      box.scrollTop = box.scrollHeight;
    }
    function scrollTop() {
      const box = document.querySelector(".msg_history");
      box.scrollTop = 0;
    }
    const checkScrollPosition = () => {
      const element = msgHistory.value;
      isScrolledToBottom.value = element.scrollHeight - element.scrollTop <= element.clientHeight +50;
      isScrolledToTop.value = element.scrollTop === 0;
    };
    const toggleMenu = () => {
      isMenuVisible.value = !isMenuVisible.value;
      if (isMenuVisible.value) {
        document.addEventListener('click', handleOutsideClick);
      } else {
        document.removeEventListener('click', handleOutsideClick);
      }
    };

    const checkRoomInfo = () => {
      showModal.value = true;  // Open the modal
    };
    const changeParticipantNumber = () => {
      newLimitMember.value = roomDetail.value.limit_member;
      showParticipantModal.value = true;  // Open the modal
    };
    const changeWolfNumber = () => {
      newWolfCount.value = roomDetail.value.wolf_count;  // Open the modal
      showWolfModal.value = true;
      // Logic to change wolf number
    };
    const changeTopics = () => {
      villageTopic.value = roomDetail.value.village_topic;
      werewolfTopic.value = roomDetail.value.werewolf_topic;
      showTopicModal.value = true;  // Open the modal
    };
     // お題確認モーダルを開く関数
    function viewTopics() {
      const currentUser = roomUsers.value.find(user => user.authorId === userId.value);
      if (currentUser) {
        userTopic.value = currentUser.team === 0 ? roomDetail.value.village_topic : roomDetail.value.werewolf_topic;
        showTopicViewModal.value = true;
      } else {
        alert("ユーザー情報が見つかりません。");
      }
    }
    // お題確認モーダルを閉じる関数
    function closeTopicViewModal() {
      showTopicViewModal.value = false;
    }
    const announceOnX = () => {
      // 現在の部屋のURLを取得
  const roomUrl = `${window.location.origin}/room/${props.roomId}`;
  // Xでの投稿URLを生成
  const xUrl = `https://x.com/intent/tweet?url=${encodeURIComponent("https://kotoniwa.jp")}&text=${encodeURIComponent("ことにわで遊ぼう！\n"+roomDetail.value.name+"\n"+roomDetail.value.detail+"\n"+roomUrl+"\n\n")}&hashtags=${encodeURIComponent("ことにわ")}`;
  // 新しいタブでXの投稿画面を開く
  window.open(xUrl, '_blank');
    };
    const closeModal = () => {
      showModal.value = false;  // Close the modal
    };
    const closeParticipantModal = () => {
      showParticipantModal.value = false;  // Close the modal
    };
    const closeWolfModal = () => {
      showWolfModal.value = false;  // Close the modal
    };
    const closeTopicModal = () => {
      showTopicModal.value = false;  // Close the modal
    };
    const toggleResultsModal = () => {
      showResultsModal.value = !showResultsModal.value;
    };
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.menu-button') && !event.target.closest('.dropdown-menu')) {
        isMenuVisible.value = false;
        document.removeEventListener('click', handleOutsideClick);
      }
    };
    const updateLimitMember = () => {
      store.dispatch('changeLimitMember', newLimitMember.value);
      closeParticipantModal();
    };
    const saveWolfCount = () => {
      store.dispatch('changeWolfCount', newWolfCount.value);
      closeWolfModal();
    };
    const saveTopics = () => {
      store.dispatch('changeTopics', { newVillageTopic: villageTopic.value, newWerewolfTopic: werewolfTopic.value });
      closeTopicModal();
    };
    const getMaxWolves = (limitMember) => Math.floor(limitMember / 2);
    function startGame() {
      if (!timer.value) {
      const startTime = serverTimestamp(); // Current time in milliseconds
      const wolfCount = roomDetail.value.wolf_count;
      const eligibleUsers = roomUsers.value.filter(user => !user.is_owner);
      const chosenWolves = chooseRandomWolves(eligibleUsers, wolfCount);
      store.dispatch('updateWolves', chosenWolves);
      store.dispatch('updateRoomStateWithTime', {
        newState: 2,
        startTime: startTime,
        endTime: null
      });
      store.dispatch('postSystemMessage', "ゲームが開始されました。お題を確認してください。");
      }
    }
    function setupTimer(startTime, endTime) {
      timer.value = setInterval(() => {
      const nowTime = new Date().getTime();
      const endTimeMillis = roomDetail.value.endTime.toMillis(); // endTimeをミリ秒に変換
      timeRemaining.value = Math.floor((endTimeMillis - nowTime) / 1000);
      if (timeRemaining.value <= 0) {
        clearInterval(timer.value);
        timer.value = null;
        timerActive.value = false;
      }
      }, 1000);
      timerActive.value = true;
    }
      // ユーザーからランダムに狼を選択する関数
      function chooseRandomWolves(users, count) {
      let shuffled = users.slice();
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled.slice(0, count);
    }
    function startVote() {
    showVoteModal.value = true;
    voteTimer.value  = 20; // タイマーをリセット
    voteTimeout.value  = setInterval(() => {
      if (voteTimer.value  > 0) {
        voteTimer.value  -= 1; // タイマーを1秒減らす
      } else {
        submitVote(); // タイマーが0になったら自動投票
      }
    }, 1000); // 1秒ごとに更新
  }
  function submitVote() {
    store.dispatch('updateVote', {
        vote:  selectedVote.value,
        authorId: store.getters.getUserId
      });
    closeVoteModal();
    showResultsModal.value = true;  // Set modal display state
  }
  function closeResultsModal() {
    showResultsModal.value = false;
  }
  function closeVoteModal() {
  showVoteModal.value = false;
  clearInterval(voteTimeout.value);  // Clear the voting timer
}
function findVoteRecipient(roomUserId) {
    return roomUsers.value.find(u => u.roomUserId === roomUserId) || { roomUserId: '不明', authorName: '不明' };
  }
const allVotesCast = computed(() => {
    return roomUsers.value.every(user => user.vote !== 0);
})
function constructResultsMessage() {
  const result = winningTeam();
  let message = `ゲーム結果: ${result}\n村お題:\n${roomDetail.value.village_topic}\n狼お題:\n ${roomDetail.value.werewolf_topic}\n投票結果:\n`;

  // Append the winning team information to the message
  roomUsers.value.forEach(user => {
    if (user.is_owner) {
      message += `${user.roomUserId}.${user.authorName} : GM\n`;
    } else {
      if(user.vote === '') {
        message += `${user.roomUserId}.${user.authorName} : 投票なし\n`;
      } else {
        const voteRecipient = findVoteRecipient(user.vote);
        message += `${user.roomUserId}.${user.authorName} → ${voteRecipient.roomUserId}.${voteRecipient.authorName}\n`;
      }
    }
  });

  return message;
}
const winningTeam = () => {
    const voteCounts = {};
    roomUsers.value.forEach(user => {
      const recipientId = user.vote;
      if (recipientId) {
        voteCounts[recipientId] = (voteCounts[recipientId] || 0) + 1;
      }
    });

    const maxVotes = Math.max(...Object.values(voteCounts));
    const mostVotedUsers = Object.keys(voteCounts).filter(id => voteCounts[id] === maxVotes);
    if (mostVotedUsers.length === 1) {
      const mostVotedUser = roomUsers.value.find(user => user.roomUserId == mostVotedUsers[0]);
      return mostVotedUser.team === 1 ? '村の勝利' : '狼の勝利';
    } else {
      return '引き分け';
    }
  }
  const increaseTimer = () => {
    if (timeRemaining.value >= 0) {
      const newEndTimeMillis = roomDetail.value.endTime.toMillis() + 60000;
      const newEndTime = Timestamp.fromMillis(roomDetail.value.endTime.toMillis() + 60000);
      store.dispatch('updateEndTime', {
        endTime: newEndTime
      })
      store.dispatch('postSystemMessage', "1分延長されました。");
    }
  }
  const decreaseTimer = () => {
    const newEndTimeMillis = roomDetail.value.endTime.toMillis() - 60000;
    const newEndTime = Timestamp.fromMillis(roomDetail.value.endTime.toMillis() - 60000);
    store.dispatch('updateEndTime', {
      endTime: newEndTime
    })
    store.dispatch('postSystemMessage', "1分短縮されました。");
  }
  const toggleMute = (user) => {
    // muteStatesにtrueの値が入っていない場合、全員をミュート状態にする
    // 全ユーザーの現在のミュート状態をコピー
    const newMuteStates = {};
    roomUsers.value.forEach(u => {
      newMuteStates[u.authorId] = muteStates.value[u.authorId] || false;
    });
    if (!Object.values(muteStates.value).some(state => state)) {
      roomUsers.value.forEach(u => {
        if (u.authorId !== user.authorId) {
          newMuteStates[u.authorId] = true;
        }
      });
      muteStates.value = newMuteStates;
    }else{
    newMuteStates[user.authorId] = !newMuteStates[user.authorId];
    if(Object.values(newMuteStates).every(state => state)){
      // 全員がミュートされている場合は、全員のミュートを解除する
      for (const key in newMuteStates) {
        newMuteStates[key] = false;
    }
    }
    muteStates.value = newMuteStates;
  }
  };
  const isMuted = (authorId) => {
    return !!muteStates.value[authorId];
  };
  // function setupPlayer(initialVideoId) {
  //     player = new YT.Player('youtube-player', {
  //       height: '390',
  //       width: '640',
  //       videoId: initialVideoId,
  //       events: {
  //         'onStateChange': onPlayerStateChange
  //       }
  //     });
  //   }

  //   // プレイヤーの状態が変わった時にFirestoreに書き込む
  //   function onPlayerStateChange(event) {
  //     if (isHost.value && [YT.PlayerState.PLAYING, YT.PlayerState.PAUSED].includes(event.data)) {
  //       const currentTime = player.getCurrentTime();
  //       videoRef.set({
  //         time: currentTime,
  //         state: event.data === YT.PlayerState.PLAYING ? 'playing' : 'paused'
  //       });
  //     }
  //   }

  //   // Firestoreのデータ変更を監視して、クライアント側のプレイヤーを更新
  //   watchEffect(() => {
  //     videoRef.onSnapshot(snapshot => {
  //       const data = snapshot.data();
  //       if (!isHost.value && player && data && data.state === 'playing') {
  //         player.seekTo(data.time, true);
  //         player.playVideo();
  //       } else if (!isHost.value && player && data && data.state === 'paused') {
  //         player.pauseVideo();
  //       }
  //     });
  //   });
    // ウォッチャーを設定して、メッセージ配列が更新されたときにスクロールダウンする
    watch(messages, () => {
      nextTick(() => {  // nextTickを使用して、DOMの更新後にスクロールする
        scrollBottom();
      });
    }
  );
    // watch(() => roomDetail.value?.state, (newState, oldState) => {
    //   if (newState === 2) {
    //     // stateが2になった時の処理
    //     console.log("Game started!");
    //     setupTimer(roomDetail.value.startTime, roomDetail.value.endTime);
    //   }
    // });
    watch(() => roomDetail.value?.startTime,(newStartTime,oldStartTime) => {
      if (!!newStartTime && roomDetail.value?.state === 2 && oldStartTime === null) {
        const gameDuration = roomDetail.value.limit_minute * 60 * 1000; // Game duration in milliseconds
        const endTime = Timestamp.fromMillis(newStartTime.toMillis() + gameDuration);
        store.dispatch('updateEndTime', {
          endTime: endTime
        });
      }
    });
    watch(() => timeRemaining.value, 
      (newVal, oldVal) => {
      if (newVal <= 0 && !showVoteModal.value && roomDetail.value.state === 2 && !!roomDetail.value.endTime) {
        if(roomDetail.value.created_by_id !== userId.value || !roomDetail.value.custom_topic) startVote();
        else {
          showResultsModal.value = true;
          //22秒経過したらその時点でvoteが0のroomUserのvoteを""に変更
          setTimeout(() => {
            roomUsers.value.forEach(user => {
          if (user.vote === 0) {
            // Dispatch an action to update the user's vote to no vote ("")
            store.dispatch('updateVote', { vote: "",
            authorId: user.authorId});
          }
        });
          }, 22000);
        }
      }
    });
    watch(() => roomDetail.value?.endTime,
      (newVal, oldVal) => {
        if(!!newVal && oldVal === null){
        setupTimer(roomDetail.value.startTime, newVal);
        }
    });
    watch(allVotesCast, (newValue) => {
      if (newValue && roomDetail.value?.state === 2) {
        if (roomDetail.value.created_by_id === userId.value) {
        // This block runs when all votes are cast
        store.dispatch('updateRoomState', { newState: 3 });
        // Inform users about the state change
        store.dispatch('postSystemMessage', "投票が終了しました。");
        // Construct the game results message
        const resultsMessage = constructResultsMessage();
        // Post the game results as a system message
        store.dispatch('postSystemMessage', resultsMessage);
        scrollBottomAbsolute();
        }
      }
    });
    function getUserName(userId) {
      const user = roomUsers.value.find(user => user.authorId === userId);
      return user ? user.authorName : 'Unknown'; // Return 'Unknown' if user is not found
    }
    function getUserRoomUserId(userId) {
      const user = roomUsers.value.find(user => user.authorId === userId);
      return user ? user.roomUserId+'.' : ''; // Return 'Unknown' if user is not found
    }
    onMounted(async () => {
      // userId のチェックと反映
      let localUserId = localStorage.getItem('userId');
      if (!localUserId) {
        //下記のuser.jsのgenerateRandomStringを呼び出す
        localUserId = await store.dispatch('generateRandomString', { length: 20 });
        localStorage.setItem('userId', localUserId);
      }
      store.dispatch('updateUserId', localUserId); // 常にストアを更新

      // userName のチェックと反映
      let localUserName = localStorage.getItem('userName');
      if (!localUserName) {
        // localUserIdの頭6桁を取得して、Guestと結合
        localUserName = 'Guest ' + localUserId.slice(0, 6);
        localStorage.setItem('userName', localUserName);
      }
      store.dispatch('updateUsername', localUserName); // 常にストアを更新

      // iconId のチェックと反映
      let localIconId = localStorage.getItem('iconId');
      if (!localIconId) {
        localIconId = '1';
        localStorage.setItem('iconId', localIconId);
      }
      store.dispatch('updateIconId', localIconId); // 常にストアを更新

      //fontSizeのチェックと反映
      let localFontSize = localStorage.getItem('fontSize');
      if (!localFontSize) {
        localFontSize = '14';
        localStorage.setItem('fontSize', localFontSize);
      }
      store.dispatch('updateFontSize', localFontSize); 
      // propsで受け取ったroomIdをストアに保存
      store.dispatch("updateRoomId", props.roomId);
      initMessages(); // コンポーネントがマウントされた時にメッセージを初期化
      autoResize();
      fetchEdcrfvtgboiu();
      store.dispatch("fetchUsers", props.roomId); // コンポーネントがマウントされた時にユーザー初期化
      store.dispatch("fetchRoomDetail", props.roomId);
      const element = msgHistory.value;
      if (element) {
        element.addEventListener('scroll', checkScrollPosition);
        checkScrollPosition();  // Perform an initial check
      }
    // window.onYouTubeIframeAPIReady = () => {
    //     videoRef.get().then(doc => {
    //       const data = doc.data();
    //       const initialVideoId = data ? data.videoId : 'M7lc1UVf-VE';
    //       setupPlayer(initialVideoId);
    //     });
    //   };
    });
    onUnmounted(() => {
      store.commit('resetLastMessageTimestamp'); // コンポーネントがアンマウントされる際にタイムスタンプをリセット
      // コンポーネントが破棄されたときにイベントリスナーをクリーンアップする
      document.removeEventListener('click', handleOutsideClick);
      const element = msgHistory.value;
      if (element) {
        element.removeEventListener('scroll', checkScrollPosition);
      }
      clearInterval(timer.value);
      // if (player) {
      //   player.destroy();
      // }
      store.dispatch("unsubscribeFromFirestore");
    });
    return {
      message,
      messages,
      saveMessage,
      authUser,
      scrollBottom,
      getIcon,
      userId,
      logout,
      initMessages,
      autoResize,
      autoResizeTextarea,
      textareaHeight,
      resizeReset,
      onTextareaInput,
      edcrfvtgboius,
      selectedFontSize,
      fontSizeMap,
      changeFontSize,
      roomDetail,
      toggleMenu,
      isMenuVisible,
      checkRoomInfo,
      changeParticipantNumber,
      changeWolfNumber,
      changeTopics,
      announceOnX,
      closeModal,
      showModal,
      showParticipantModal,
      closeParticipantModal,
      newLimitMember,
      updateLimitMember,
      showWolfModal,
      closeWolfModal,
      newWolfCount,
      saveWolfCount,
      getMaxWolves,
      showTopicModal,
      closeTopicModal,
      villageTopic,
      werewolfTopic,
      saveTopics,
      announceOnX,
      roomUsers,
      constRoomId,
      inRoomId,
      joinRoom,
      isUserInRoom,
      startGame,
      timerActive,
      displayTime,
      timeRemaining,
      timer,
      viewTopics,
      closeTopicViewModal,
      showTopicViewModal,
      userTopic,
      showVoteModal,
      showResultsModal,
      voteTimer,
      selectedVote,
      submitVote,
      startVote,
      votes,
      closeResultsModal,
      closeVoteModal,
      allVotesCast,
      winningTeam,
      toggleResultsModal,
      constructResultsMessage,
      findVoteRecipient,
      werewolves,
      increaseTimer,
      decreaseTimer,
      isHost,
      checkScrollPosition,
      isScrolledToBottom,
      isScrolledToTop,
      msgHistory,
      scrollTop,
      scrollBottomAbsolute,
      muteStates,
      toggleMute,
      isMuted,
      selectedRecipient,
      privateMessageStyle,
      getUserName,
      getUserRoomUserId,
      filteredUsers,
    };
  }
};
</script>

<style scoped>
/* .room-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  top: 20vh;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100%;
} */
:root {
  --base-font-size: 14px; /* Default base font size */
}
.room-container {
  position: absolute;
  /* Using absolute to position within the relative parent */
  top: 0%;
  /* Margin from top */
  bottom: 0%;
  /* Margin from bottom */
  left: 0%;
  /* Margin from left */
  right: 0%;
  /* Margin from right */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* Adjust as necessary */
  /* Optional: add transparency or color */
  font-size: var(--base-font-size); /* Use the base font size */
}
.header {
  display: flex;
  justify-content: space-between; /* Aligns elements to the right */
  padding: 0px;
  width: 100%;
  align-items: center; /* To align items vertically */
}
.header-group {
  display: flex;
  align-items: center; /* Ensures items in the group are vertically centered */
}
.font-size-selector {
  margin-right: 10px; /* Space between dropdown and exit button */
  padding: 0px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  height: 32px;
  vertical-align: middle;
}
.font-size-container {
  display: flex; /* To align label and select horizontally */
  align-items: center; /* To align items vertically */
}
img {
  max-width: 100%;
}

/* .mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
} */
.mesgs {
  width: 100%;
  /* Allocate space for input area */
  height: 100svh;
  overflow-y: auto;
}

.msg_history {
  overflow-y: auto;
  height: calc(100svh - 80px);
  background: rgba(255, 255, 255, 0.8);
}

/* .type_msg {
  position: fixed; 
  bottom: 0;
  left: 15%;
  width: 70%;
} */
.type_msg {
  width: 100%; /* テキストエリアの幅を全体に合わせる */
  max-width: 1320px; /* テキストエリアの最大幅を全体に合わせる */
  position: fixed;
  bottom: 0;
  left: calc(100svw - 1320px) / 2; /* テキストエリアを中央に配置 */
  padding: 10px 15px;
  background-color: #f8f8f8; /* 背景色の設定 */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* 上方向の影を追加 */
  z-index: 100; /* 他の要素の上に表示 */
}

/* .input_msg_write {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
} */
.input_msg_write {
  display: flex;
  justify-content: center;
  /* Centers all children horizontally */
  align-items: center;
  /* Aligns children vertically in the middle */
  /* padding: 0px; */
  width: 100%;
}

/* .input_msg_write input {
  flex-grow: 1;
  margin-right: 10px;
} */

/* .msg_send_btn {
  flex-shrink: 0;
} */
.msg_send_btn {
  flex-shrink: 0;
  /* Prevents the button from shrinking when the input field expands */
  width: 40px;
  /* Fixed width for the button */
  height: 40px;
  /* Fixed height for the button */
  padding: 5px;
  /* Reduced padding */
  background-color: #4CAF50;
  /* Example button color, adjust as needed */
  color: white;
  /* Text color for the icon */
  border: none;
  /* Removes border */
  border-radius: 50%;
  /* Makes the button round */
  cursor: pointer;
  /* Cursor changes to pointer to indicate it's clickable */
  /* 親要素内の縦方向の中心に配置 */
  align-self: center;
}
.recipient-selector {
  margin-right: 10px; /* ドロップダウンとテキストエリアの間隔 */
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}
/* 追加：アイコンスタイル調整 */
.fa-paper-plane-o {
  font-size: 16px; /* アイコンサイズ調整 */
}
.input_msg_write input {
  flex-grow: 1;
  /* Ensures input field takes remaining space */
  margin-right: 10px;
  /* Space between input field and button */
}

/* 四角い画像を丸くする */
.received_msg_img,
.sent_msg_img {
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  bottom: 0%;
  /* Margin from bottom */
  flex-shrink: 0;
  display: inline-block;
  overflow: hidden;
}
.sent_msg_img {
  margin-left: 10px;
}

.received_msg_img {
  margin-right: 10px;
}

.sent_msg {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.sent_msg p {
  background: #151339;
  border-radius: 3px;
  font-size: var(--base-font-size); /* Apply base font size */
  color: #fff;
  padding: 5px 5px;
  margin-right: 0px;
  word-break: break-word;
  overflow-wrap: break-word;
  /* Space between message and image */
  white-space: pre-wrap;
  margin-bottom: 3px;
}

.received_msg {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}

/* .received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
} */
.received_withd_msg {
  display: flex;
  flex-direction: column;
  /* 子要素を縦に並べる */
}

.received_withd_msg p {
  background: #ebebeb;
  border-radius: 8px;
  color: #646464;
  font-size: var(--base-font-size); /* Apply base font size */
  padding: 5px 5px;
  margin-left: 0px;
  word-break: break-word;
  overflow-wrap: break-word;
  /* Space between image and message */
  white-space: pre-wrap;
  margin-bottom: 3px;
}

.time_date {
  color: #747d8c;
  font-size: 12px;
  margin-left: 5px;
}

/* .received_withd_msg {
  width: 57%;
} */
.sent_author_name {
  color: #747d8c;
  /* Adjust color as needed */
  font-size: var(--base-font-size - 1); /* Apply base font size */
  /* Adjust font size as needed */
  font-weight: bold;
  /* Optional: if you want author's name to stand out */
  margin-bottom: 0px;
  /* Space between author's name and message */
  /* 右端に表示 */
  text-align: right;
}

.received_author_name {
  color: #747d8c;
  /* Adjust color as needed */
  font-size: var(--base-font-size - 1); /* Apply base font size */
  /* Adjust font size as needed */
  font-weight: bold;
  /* Optional: if you want author's name to stand out */
  margin-bottom: 0px;
  /* Space between author's name and message */
  /* 右端に表示 */
  text-align: left;
}

/* .logout-button {
  position: absolute;
  top: 0px;
  right: 0%;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
  height: 32px;
} */
.logout-button {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.write_msg {
  flex-grow: 1; /* 可能な限りの幅を使用 */
  margin-right: 10px; /* ボタンとの間隔 */
  padding: 8px;
  border: 1px solid #ccc; /* 枠線 */
  border-radius: 4px; /* 角丸 */
  resize: none; /* リサイズ禁止 */
}
.menu-button {
  padding: 8px 12px; /* Increased padding */
  font-size: 18px; /* Larger font size for better visibility */
  cursor: pointer;
  background-color: #4CAF50; /* A distinct, theme-based background color */
  color: white; /* White text color for contrast */
  border: none;
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for 3D effect */
  transition: all 0.3s ease; /* Smooth transition for visual effects */
  margin-right: auto; /* Keeps the button on the left */
  outline: none; /* Removes outline */
}

.menu-button:hover, .menu-button:focus {
  background-color: #367c39; /* Darker shade on hover/focus */
  box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Larger shadow on hover/focus */
}

.menu-button:active {
  transform: scale(0.95); /* Slight scale down on click */
}
.dropdown-menu {
  display: block; /* Ensure it's not set to 'none' somewhere dynamically */
  position: absolute;
  top: 32px; /* Adjust based on header height */
  width: auto; /* or set a specific width */
  min-width: 160px; /* Adjust if it's too small to show content */
  overflow: visible; /* Ensure it's not hidden or scrolled */
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
}

.dropdown-menu li {
  padding: 12px 16px;
  text-align: left;
  cursor: pointer;
  display: block; /* Make sure it’s not set to 'none' */
  color: black; /* Ensure text is visible against the background */
  font-size: 16px; /* Make sure font size is reasonable */
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 600px;
}

.close {
  float: right;
  font-size: 28px;
  cursor: pointer;
}
.message-system {
  color: #888 !important; 
  font-style: italic !important;
  background-color: lightgreen !important;
  border-radius: 10px !important;
}
.topics-textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: vertical; /* ユーザーによる垂直方向のリサイズのみを許可 */
}
.login-button {
  padding: 5px 10px;
  background-color: #4CAF50; /* 入室ボタンは緑色に設定 */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.user-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* 左寄せで配置 */
    background: rgba(255, 255, 255, 0.8);
    padding: 10px; /* パディングを設定 */
    width: 100%; /* コンテナ全体の幅 */
    overflow-x: auto;
  }

  .user-entry {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 左寄せ */
    margin: 0px 0; /* 上下のマージン */
  }

  .user-icon {
    width: 40px; /* アイコンのサイズ */
    height: 40px;
    border-radius: 50%; /* アイコンを円形に */
    /* 真ん中に配置 */
    align-self: center;
  }

  .user-name {
    font-size: 14px; /* 名前のフォントサイズ */
    color: #000000; /* 名前の色 */
    margin-top: 0px; /* アイコンと名前の間のスペース */
  }
  .start-button {
  padding: 5px 10px;       /* Padding around the text */
  background-color: #4CAF50; /* Green background to signify action */
  color: white;            /* White text color for contrast */
  font-size: 14px;         /* Font size similar to other interface elements */
  border: none;            /* No border for a cleaner look */
  border-radius: 5px;      /* Rounded corners */
  cursor: pointer;         /* Cursor changes to pointer to indicate it's clickable */
  margin-right: 10px;      /* Right margin for spacing between elements */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}

.start-button:hover, .start-button:focus {
  background-color: #367C39; /* Darker shade of green when hovered or focused */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Shadow effect for depth */
}

.start-button:active {
  transform: scale(0.95);  /* Slight scale down on click to give feedback */
}
.topic-button {
  padding: 5px 10px;       /* Padding around the text */
  /* 濃い灰色 */
  background-color: #333; /* Green background to signify action */
  color: white;            /* White text color for contrast */
  font-size: 14px;         /* Font size similar to other interface elements */
  border: none;            /* No border for a cleaner look */
  border-radius: 5px;      /* Rounded corners */
  cursor: pointer;         /* Cursor changes to pointer to indicate it's clickable */
  margin-right: 10px;      /* Right margin for spacing between elements */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}
.topic-button:hover, .topic-button:focus {
  background-color: #666; /* Darker shade of green when hovered or focused */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Shadow effect for depth */
}

.topic-button:active {
  transform: scale(0.95);  /* Slight scale down on click to give feedback */
}
.timer-display {
  font-size: 1.5rem; /* Larger font for visibility */
  color: #fff; /* White color for contrast */
  padding-left: 0.5rem; /* Padding for spacing */
  padding-right: 0.5rem; /* Padding for spacing */
  background-color: #4CAF50; /* Background to match the start button */
  margin: 0 auto; /* Center the timer display */
  height:50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topic-text {
  white-space: pre-wrap; /* 保持されたスペースや改行を適用 */
  text-align: left;      /* テキストを左揃えに */
  margin: 15px 0;        /* 上下のマージンを設定 */
  color: #333;           /* テキストの色を指定 */
  font-size: 16px;       /* フォントサイズを指定 */
}
.voting-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.voting-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 90%;
  max-width: 500px;
}

.voting-modal h3 {
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.voting-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.voting-button {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.voting-button:hover {
  background-color: #367C39;
}

.voting-button:disabled {
  background-color: #cccccc;
  cursor: default;
}
.results-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75); /* Dark overlay to focus on the modal */
  z-index: 2000;
}

.results-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.close-modal {
  float: right;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}
.topic-header {
  margin-top: 20px;
  color: #5a5a5a;
  font-size: 20px;
  font-weight: bold;
}

.topic-content {
  color: #333;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f7f7f7;
  border-left: 0px solid #4CAF50; /* Green stripe for emphasis */
  text-align: left;
  white-space: pre-wrap; /* 保持されたスペースや改行を適用 */
}

.vote-results-list {
  list-style-type: none;
  padding: 0;
}

.vote-result-item {
  padding: 10px;
  margin-top: 5px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ececec;
}

.vote-result-item:nth-child(odd) {
  background-color: #e9e9e9;
}

.vote-result-owner {
  font-style: italic;
  color: #c76c04; /* Different color for GM */
}

.vote-result-normal {
  color: #087f23; /* Green color for normal votes */
}

.team-villager {
  color: green;
}

.team-werewolf {
  color: red;
}
.results-button {
  margin-left: auto; /* Aligns the button to the right side of the header */
  padding: 5px 10px;
  background-color: #4CAF50; /* Consistent styling with other buttons */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.results-button:hover {
  background-color: #367C39; /* Darker on hover for feedback */
}
.timer-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.timer-button:hover {
  background-color: #367C39;
}

.scroll-buttons {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  z-index: 10;
}

.scroll-button {
  background-color: rgba(255, 255, 255, 0.0);
  border: none;
  cursor: pointer;
  /* 文字色も透過させる */
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 30px;
  height: 30px;
  display: block;
  margin: 5px 0;
  transition: background-color 0.3s;
}

.scroll-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.user-icon.muted {
  opacity: 0.4;
}

.mute-indicator {
  color: red;
  margin-left: 10px; /* Space between name and icon */
}
</style>
