<template>
    <div class="create-room-container">
        <h2>部屋を作る</h2>
            <!-- Room Type -->
            <div class="form-group row">
                <label for="room-type" class="col-sm-4 col-form-label">部屋の種類:</label>
                <div class="col-sm-8">
                    <select id="room-type" v-model="roomType" required aria-label="部屋の種類を選択してください" class="form-control">
                        <option value="1">ワードウルフ</option>
                        <option value="2">雑談</option>
                        <option value="3">その他</option>
                    </select>
                </div>
            </div>
            <!-- Conditional Fields: Room Time Limit (only if not "雑談") -->
            <div v-if="roomType !== 2">
                <div class="form-group row">
                    <label for="limit-minute" class="col-sm-4 col-form-label">プレイ時間 (分):</label>
                    <div class="col-sm-8 d-flex align-items-center">
                        <button @click="decrementLimitMinute()" class="btn-control">-</button>
                        <input type="text" id="limit-minute" v-model="limitMinute" class="form-control text-center" disabled />
                        <button @click="incrementLimitMinute()" class="btn-control">+</button>
                    </div>
                </div>
            </div>
            <!-- Number of Player -->
            <div class="form-group row">
                <label for="limit-member" class="col-sm-4 col-form-label">部屋の人数:</label>
                <div class="col-sm-8 d-flex align-items-center">
                    <button @click="decrementLimitMember()" class="btn-control">-</button>
                    <input type="text" id="limit-member" v-model="limitMember" class="form-control text-center" disabled />
                    <button @click="incrementLimitMember()" class="btn-control">+</button>
                </div>
            </div>
            <div v-if="roomType != 2">
                <!-- Number of Werewolves -->
                <div class="form-group row">
                    <label for="wolf-count" class="col-sm-4 col-form-label">狼の人数:</label>
                    <div class="col-sm-8">
                        <select id="wolf-count" v-model="wolfCount" class="form-control">
                            <option value="0">0</option>
                            <option v-for="n in getMaxWolves(limitMember)" :value="n" :key="n">{{ n }}</option>
                            <!-- <option value="random">ランダム(平和なし)</option>
                            <option value="random2">ランダム(平和あり)</option> -->
                        </select>
                    </div>
                </div>
            </div>
            <!-- Room Name -->
            <div class="form-group row">
                <label for="room-name" class="col-sm-4 col-form-label">部屋の名前:</label>
                <div class="col-sm-8">
                    <input type="text" id="room-name" autocomplete="off" v-model="roomName" required
                        class="form-control" placeholder="部屋の名前を入力してください" />
                </div>
            </div>

            <!-- Room Details -->
            <div class="form-group row">
                <label for="room-detail" class="col-sm-4 col-form-label">部屋の詳細:</label>
                <div class="col-sm-8">
                    <textarea id="room-detail" autocomplete="off" v-model="roomDetail" class="form-control"
                        placeholder="部屋の詳細を入力してください"></textarea>
                </div>
            </div>

            <!-- Checkbox for Custom Topic -->
            <div v-if="roomType == 1" class="form-group d-flex align-items-center">
                <label for="custom-topic" class="col-sm-4 col-form-label">お題を自作</label>
                <!-- 左寄せ -->
                <div class="col-sm-8 d-flex justify-content-start">
                    <input type="checkbox" id="custom-topic" v-model="isCustomTopic" />
                </div>
            </div>

            <!-- Conditional Fields: Custom Topics -->
            <div v-if="isCustomTopic || roomType == 3">
                <div class="form-group row">
                    <label for="village-topic" class="col-sm-4 col-form-label">村側のお題:</label>
                    <div class="col-sm-8">
                        <textarea  id="village-topic" v-model="villageTopic" class="form-control"
                            placeholder="村側のお題を入力してください" />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="werewolf-topic" class="col-sm-4 col-form-label">狼側のお題:</label>
                    <div class="col-sm-8">
                        <textarea id="werewolf-topic" v-model="werewolfTopic" class="form-control"
                            placeholder="狼側のお題を入力してください" />
                    </div>
                </div>
            </div>

            <!-- Room Details -->
            <!-- <div v-if="roomType == 3" class="form-group row">
                <label for="room-rule" class="col-sm-4 col-form-label">ルール説明:</label>
                <div class="col-sm-8">
                    <textarea id="room-rule" autocomplete="off" v-model="roomRule" class="form-control"
                        placeholder="ルール説明を入力してください"></textarea>
                </div>
            </div> -->

            <!-- Public/Private Selection -->
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">部屋の公開設定:</label>
                <div class="col-sm-8 d-flex flex-column flex-sm-row align-items-center">
                    <input type="radio" id="public" v-model="isPublic" value="public" class="col-sm-1" />
                    <!-- 左寄せにしたい -->
                    <label for="public" class="col-sm-4" style="">公開</label>
                    <input type="radio" id="private" v-model="isPublic" value="private" class="col-sm-1" />
                    <label for="private" class="col-sm-4">非公開</label>
                </div>
                <div v-if="isPublic === 'private'" class="form-text">
                    非公開の場合、一覧に部屋が表示されません。URLをお友達に共有してお楽しみください。
                </div>
            </div>

            <div v-if="roomType == 3" class="form-group row">
                <label class="col-sm-4 col-form-label">個別メッセージ機能:</label>
                <div class="col-sm-8 d-flex flex-column flex-sm-row align-items-center">
                    <input type="radio" id="useTargetMessageTrue" v-model="useTargetMessage" value="true" class="col-sm-1" />
                    <!-- 左寄せにしたい -->
                    <label for="useTargetMesageTrue" class="col-sm-4" style="">使用する</label>
                    <input type="radio" id="useTargetMessageFalse" v-model="useTargetMessage" value="false" class="col-sm-1" />
                    <label for="useTargetMesageFalse" class="col-sm-4">使用しない</label>
                </div>
            </div>

            <!-- Submit Button -->
            <div class="form-group row">
                <!-- 右寄せ -->
                <div class="col-sm-12" style="text-align: right;">
                    <button type="submit" class="submit-button" @click="createRoom()">作成</button>
                </div>
            </div>

        <!-- Back Button -->
        <button @click="goBack" class="back-button">戻る</button>
    </div>
</template>

<script>
import { ref,onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import {
    collection,
    addDoc,
    query,
    orderBy,
    where,
    onSnapshot,
    setDoc,
    doc,
    getDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

export default {
    setup() {
        const router = useRouter();
        const store = useStore();
        const roomName = ref("");
        const roomDetail = ref("");
        const roomType = ref(1);
        const limitMinute = ref(3);
        const limitMember = ref(5);
        const wolfCount = ref("1");
        const isCustomTopic = ref(false);
        const villageTopic = ref("");
        const werewolfTopic = ref("");
        const isPublic = ref("public");
        const roomRule = ref("");
        const useTargetMessage = ref("false");

        const getMaxWolves = (limitMember) => Math.floor(limitMember / 2);

        const createRoom = async () => {
            if (wolfCount.value != 'random' && wolfCount.value >= limitMember.value / 2) {
                alert("狼の人数は部屋人数の半分未満でなければなりません");
                return;
            }
            saveSettingsToLocalStorage();
            // 部屋番号となるuuidを生成
            const roomId = uuidv4();
            const topics = await fetchTopics();
            const setVillageTopic = !!isCustomTopic.value ? villageTopic.value : topics.villageTopic;
            const setWerewolfTopic = !!isCustomTopic.value ? werewolfTopic.value : topics.werewolfTopic;
            const newRoom = {
                // 生成したuuidをidに設定する
                id: roomId,
                state: 1,
                name: roomName.value,
                detail: roomDetail.value,
                type: parseInt(roomType.value),
                created_at: new Date().toISOString(),
                limit_minute: parseInt(limitMinute.value),
                limit_member: limitMember.value,
                wolf_count: wolfCount.value,
                custom_topic: isCustomTopic.value || roomType.value == 3,
                village_topic: setVillageTopic,
                werewolf_topic: setWerewolfTopic,
                created_by: store.getters.getUsername,
                created_by_id: store.getters.getUserId,
                created_user_icon_id: store.getters.getIconId,
                is_public: isPublic.value,
                in_user_count: 1,
                startTime:null,
                endTime:null,
                messages: [
                    {
                        authorIconId: store.getters.getIconId,
                        authorId: store.getters.getUserId,
                        authorName: store.getters.getUsername,
                        created_at: new Date(),
                        createdAtTime: new Date().toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                        }),
                    },
                ],
                rule: roomRule.value,
                use_target_message: useTargetMessage.value,
            };
            // firestoreに部屋情報を追加
            setDoc(doc(db, "rooms", roomId), newRoom)
                .then(() => {
                    // 成功したら、メッセージサブコレクションに初期メッセージを追加
                    const message = {
                        authorIconId: store.getters.getIconId,
                        authorId: store.getters.getUserId,
                        authorName: store.getters.getUsername,
                        message: store.getters.getUsername + "が部屋を作成しました",
                        createdAt: new Date(),
                        createdAtTime: new Date().toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                        }),
                    };
                    return addDoc(collection(db, "rooms", roomId, "messages"), message);
                })
                .then(() => {
                    // 成功したら、メッセージサブコレクションに初期メッセージを追加
                    const user = {
                        authorIconId: store.getters.getIconId,
                        authorId: store.getters.getUserId,
                        authorName: store.getters.getUsername,
                        roomUserId: 1,
                        is_owner: isCustomTopic.value || roomType.value == 3,
                        // 陣営 0:村人 1:人狼
                        team: 0,
                        //　投票先
                        vote: isCustomTopic.value ? "" : 0,
                    };
                    return setDoc(doc(db, "rooms", roomId, "users",store.getters.getUserId), user);
                })
                .then(() => {
                    store.dispatch("updateInRoomId", roomId);
                    router.push({ name: "Room", params: { roomId: roomId } });
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
            goBack();
        };

        const goBack = () => {
            router.go(-1);
        };
        const incrementLimitMinute = () => {
            if(limitMinute.value < 20){
                limitMinute.value++;
            }
        };

        const decrementLimitMinute = () => {
            if (limitMinute.value > 1) {
                limitMinute.value--;
            }
        };
        const incrementLimitMember = () => {
            if(limitMember.value < 20){
                limitMember.value++;
            }
        };
        const decrementLimitMember = () => {
            if (limitMember.value > 3) {
                limitMember.value--;
            }
        };
        const saveSettingsToLocalStorage = () => {
            const settings = {
                roomName: roomName.value,
                roomDetail: roomDetail.value,
                roomType: roomType.value,
                limitMinute: limitMinute.value,
                limitMember: limitMember.value,
                wolfCount: wolfCount.value,
                isCustomTopic: isCustomTopic.value,
                villageTopic: villageTopic.value,
                werewolfTopic: werewolfTopic.value,
                isPublic: isPublic.value,
                roomRule: roomRule.value,
                useTargetMessage: useTargetMessage.value,
            };
            localStorage.setItem('kotoniwaRoomSettings', JSON.stringify(settings));
        };
        const loadSettingsFromLocalStorage = () => {
            const settings = JSON.parse(localStorage.getItem('kotoniwaRoomSettings'));
            if (settings) {
                roomName.value = settings.roomName;
                roomDetail.value = settings.roomDetail;
                roomType.value = settings.roomType;
                limitMinute.value = settings.limitMinute;
                limitMember.value = settings.limitMember;
                wolfCount.value = settings.wolfCount;
                isCustomTopic.value = settings.isCustomTopic;
                villageTopic.value = settings.villageTopic;
                werewolfTopic.value = settings.werewolfTopic;
                isPublic.value = settings.isPublic;
                roomRule.value = settings.roomRule;
                useTargetMessage.value = settings.useTargetMessage;
            }
        };
        const fetchTopics = async () => {
            const topicsCountRef = doc(db, "topicsCount", "countData");
            const countSnap = await getDoc(topicsCountRef);
            if (countSnap.exists()) {
                const count = countSnap.data().count;
                const randomDocId = Math.floor(Math.random() * count) + 1;
                const topicsRef = doc(db, "topics", randomDocId.toString());
                const topicSnap = await getDoc(topicsRef);
                if (topicSnap.exists()) {
                    const topics = topicSnap.data().topics.split(',').map(t => t.trim());
                    let villageTopic, werewolfTopic;
                    do {
                        villageTopic = topics[Math.floor(Math.random() * topics.length)];
                        werewolfTopic = topics[Math.floor(Math.random() * topics.length)];
                    } while (villageTopic === werewolfTopic); // Ensure they are different
                return { villageTopic, werewolfTopic };
                }
            }
            return { villageTopic: "", werewolfTopic: "" }; // Return empty if no data found
        };
        onMounted(() => {
            loadSettingsFromLocalStorage();
        });
        return {
            roomName,
            roomDetail,
            roomType,
            limitMinute,
            limitMember,
            wolfCount,
            villageTopic,
            werewolfTopic,
            isCustomTopic,
            createRoom,
            goBack,
            getMaxWolves,
            isPublic,
            incrementLimitMinute,
            decrementLimitMinute,
            incrementLimitMember,
            decrementLimitMember,
            roomRule,
            useTargetMessage,
            loadSettingsFromLocalStorage,
            saveSettingsToLocalStorage,
            fetchTopics,
        };
    },
};
</script>

<style scoped>
.create-room-container {
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    width: 90%;
    max-width: 600px;
    margin: auto;
    border-radius: 10px;
    text-align: center;
    position: relative;
    top: 3svh;
    overflow-y: auto;
    max-height: 90vh;
}

.form-group {
    display: flex;
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

input,
textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

label {
    font-weight: bold;
    flex: 1;
}

button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

button:active {
    background-color: #004080;
}

.submit-button {
    text-transform: uppercase;
    letter-spacing: 1px;
}

.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
}

.form-text {
    font-size: 12px;
    color: #555;
}

.radio-buttons-left {
    justify-content: start;
    /* Align items to the left */
    margin-bottom: 0;
    /* Remove bottom margin */
}

.radio-buttons-left label {
    margin-bottom: 0;
    /* Remove bottom margin for labels */
}
textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: vertical; /* ユーザーによる垂直方向のリサイズのみを許可 */
}
.btn-control {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #007bff;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-control:active {
    background-color: #0056b3;
}

.form-control[disabled] {
    background-color: #e9e9e9;
    color: #666;
    cursor: not-allowed;
}

.d-flex.align-items-center {
    display: flex;
    align-items: center;
}

</style>