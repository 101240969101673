<template>
  <!-- 内容をセンタリングして表示する -->
  <div class="container-xxl" style="padding: 0;position: relative; height: 100svh; overflow: hidden;">
    <div class="bg-image"></div>
  <router-view />
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { onMounted } from 'vue';
export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    // ページロード時に localStorage をチェックし、Vuex ストアに反映
    onMounted(async () => {
      // userId のチェックと反映
      let localUserId = localStorage.getItem('userId');
      if (!localUserId) {
        //下記のuser.jsのgenerateRandomStringを呼び出す
        localUserId = await store.dispatch('generateRandomString', { length: 20 });
        localStorage.setItem('userId', localUserId);
      }
      store.dispatch('updateUserId', localUserId); // 常にストアを更新

      // userName のチェックと反映
      let localUserName = localStorage.getItem('userName');
      if (!localUserName) {
        // localUserIdの頭6桁を取得して、Guestと結合
        localUserName = 'Guest ' + localUserId.slice(0, 6);
        localStorage.setItem('userName', localUserName);
      }
      store.dispatch('updateUsername', localUserName); // 常にストアを更新

      // iconId のチェックと反映
      let localIconId = localStorage.getItem('iconId');
      if (!localIconId) {
        localIconId = '1';
        localStorage.setItem('iconId', localIconId);
      }
      store.dispatch('updateIconId', localIconId); // 常にストアを更新

      //fontSizeのチェックと反映
      let localFontSize = localStorage.getItem('fontSize');
      if (!localFontSize) {
        localFontSize = '14';
        localStorage.setItem('fontSize', localFontSize);
      }
      store.dispatch('updateFontSize', localFontSize); // 常にストアを更新
    });
  }
};
</script> 
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: "FOT-マティス Pro B";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/* メディアクエリを使用して解像度に応じた背景画像を設定 */
@media (max-width: 1440px) {
  .bg-image {
    background-image: url('./assets/background-phone.jpg');
  }
}
</style>
