<template>
    <div class="room-list-container">
        <!-- 戻るボタン -->
        <div class="header" style="height: 32px;min-height: 32px;">
            <button @click="login" class="logout-button">戻る</button>
        </div>
        <div class="tab-header">
            <!-- Check if tabs are displaying -->
            <button v-for="(tab, index) in tabs" :key="index" :class="{ 'active': activeTab === index }"
                @click="setActiveTab(index)">
                {{ tab }}
            </button>
        </div>
        <div class="tab-content" style="height: calc(100svh - 128px)">
            <!-- Verify if activeTab is set correctly -->
            <div v-if="activeTab === 0">
                <!-- Content for "募集中" -->
                <div v-for="room in permanentRooms" :key="room.id" class="room-card" @click="goToRoom(room.id)">
                    <div class="room-info">
                        <!-- Type tag on the top left -->
                        <div class="room-type" :class="getTypeClass(room.type)">
                            {{ getRoomType(room.type) }}
                        </div>
                        <!-- Members and Time Limit in the same row, aligned horizontally -->
                        <div class="room-meta">
                            <span>部屋人数: {{ room.in_user_count }}/-</span>
                            <!-- 雑談の場合は表示しない -->
                            <div v-if="room.type !== 2">
                                <span>プレイ時間: {{ room.limit_minute }}分</span>
                            </div>
                        </div>
                        <!-- Icon and Created By on the top right -->
                        <div class="room-creator">
                            <img :src="getIcon(room.created_user_icon_id)" alt="creator-icon" class="creator-icon" />
                            <span class="created-by">{{ room.created_by }}</span>
                        </div>
                    </div>
                    <!-- Room name on the second row -->
                    <h3 class="room-name">{{ room.name }}</h3>
                    <!-- Room detail on the third row -->
                    <p class="room-detail">{{ room.detail }}</p>
                </div>
            </div>
            <div v-if="activeTab === 1">
                <!-- Content for "募集中" -->
                <div v-for="room in waitingRooms" :key="room.id" class="room-card" @click="goToRoom(room.id)">
                    <div class="room-info">
                        <!-- Type tag on the top left -->
                        <div class="room-type" :class="getTypeClass(room.type)">
                            {{ getRoomType(room.type) }}
                        </div>
                        <!-- Members and Time Limit in the same row, aligned horizontally -->
                        <div class="room-meta">
                            <span>部屋人数: {{ room.in_user_count }}/{{ room.limit_member }}</span>
                            <!-- 雑談の場合は表示しない -->
                            <div v-if="room.type !== 2">
                                <span>プレイ時間: {{ room.limit_minute }}分</span>
                            </div>
                        </div>
                        <!-- Icon and Created By on the top right -->
                        <div class="room-creator">
                            <img :src="getIcon(room.created_user_icon_id)" alt="creator-icon" class="creator-icon" />
                            <span class="created-by">{{ room.created_by }}</span>
                        </div>
                    </div>
                    <!-- Room name on the second row -->
                    <h3 class="room-name">{{ room.name }}</h3>
                    <!-- Room detail on the third row -->
                    <p class="room-detail">{{ room.detail }}</p>
                </div>
            </div>
            <div v-if="activeTab === 2">
                <!-- Content for "ゲーム中" -->
                <div v-for="room in activeRooms" :key="room.id" class="room-card" @click="goToRoom(room.id)">
                    <div class="room-info">
                        <div class="room-type" :class="getTypeClass(room.type)">
                            {{ getRoomType(room.type) }}
                        </div>
                        <div class="room-meta">
                            <span>部屋人数: {{ room.in_user_count }}/{{ room.limit_member }}</span>
                            <!-- 雑談の場合は表示しない -->
                            <div v-if="room.type !== 2">
                                <span>プレイ時間: {{ room.limit_minute }}分</span>
                            </div>
                        </div>
                        <div class="room-creator">
                            <img :src="getIcon(room.created_user_icon_id)" alt="creator-icon" class="creator-icon" />
                            <span class="created-by">{{ room.created_by }}</span>
                        </div>
                    </div>
                    <h3 class="room-name">{{ room.name }}</h3>
                    <p class="room-detail">{{ room.detail }}</p>
                </div>
            </div>
            <div v-if="activeTab === 3">
                <!-- Content for "終了済み" -->
                <div v-for="room in finishedRooms" :key="room.id" class="room-card" @click="goToRoom(room.id)">
                    <div class="room-info">
                        <div class="room-type" :class="getTypeClass(room.type)">
                            {{ getRoomType(room.type) }}
                        </div>
                        <div class="room-meta">
                            <span>部屋人数: {{ room.in_user_count }}/{{ room.limit_member }}</span>
                            <div v-if="room.type !== 2">
                                <span>プレイ時間: {{ room.limit_minute }}分</span>
                            </div>
                        </div>
                        <div class="room-creator">
                            <img :src="getIcon(room.created_user_icon_id)" alt="creator-icon" class="creator-icon" />
                            <span class="created-by">{{ room.created_by }}</span>
                        </div>
                    </div>
                    <h3 class="room-name">{{ room.name }}</h3>
                    <p class="room-detail">{{ room.detail }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="footer" style="height: 32px;min-height: 32px;">
        <!-- 部屋を作るボタンを押下不可にする -->
        <button @click="createRoom" class="create-room-button">部屋を作る</button>
        <!-- <button @click="createRoom" class="create-room-button">部屋を作る</button> -->
    </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { IconUrlConst } from '../consts/iconsConst';
import { useRouter } from "vue-router";

export default {
    setup() {
        const store = useStore();
        const activeTab = ref(1); // Default to the first tab
        const tabs = ["常設","募集中", "ゲーム中", "終了済み"];
        const router = useRouter();
        function getIcon(iconId) {
            return IconUrlConst[iconId] || '';
        }
        onMounted(() => {
            store.dispatch('fetchRooms');  // Assuming the action is named 'listenForRoomsUpdates'
        });
        // Sample data for rooms
        // type: 1: ワードウルフ, 2: 雑談, 3: その他
        const rooms = computed(() => store.getters.getRooms);
        const getRoomType = (type) => {
            const types = {
                1: "ワードウルフ",
                2: "雑談",
                3: "その他",
            };
            return types[type] || "不明";
        };
        // ログインページに戻る
        const login = () => {
            router.push('/login');  // ログイン画面へのルートを適宜調整してください
        };
        const createRoom = () => {
            router.push('/create-room'); // Route to room creation screen
        };
        const getTypeClass = (type) => {
            switch (type) {
                case 1:
                    return "tag-ww";
                case 2:
                    return "tag-chat";
                case 3:
                    return "tag-other";
                default:
                    return "tag-unknown";
            }
        };
        //常設の部屋を取得
        const permanentRooms = computed(() =>
            rooms.value.filter((room) => room.state == 0)
        );
        const waitingRooms = computed(() =>
            rooms.value.filter((room) => room.state === 1)
        );
        const activeRooms = computed(() =>
            rooms.value.filter((room) => room.state === 2)
        );
        const finishedRooms = computed(() =>
            rooms.value.filter((room) => room.state === 3)
        );

        const setActiveTab = (index) => {
            activeTab.value = index;
        };

        const goToRoom = (roomId) => {
            router.push({ name: "Room", params: { roomId: roomId } });
        };

        return {
            tabs,
            activeTab,
            setActiveTab,
            waitingRooms,
            activeRooms,
            finishedRooms,
            getIcon,
            getRoomType,
            getTypeClass,
            goToRoom,
            login,
            createRoom,
            permanentRooms,
        };
    },
};
</script>

<style scoped>
.room-list-container {
    margin: auto;
    /* Centering component */
    padding: 0px;
    /* Ensure padding for better layout */
    position: absolute;
    align-items: center;
    width: 100%;
    /* 画面の左右中央に配置するためのスタイル */
    left: 50%;
    transform: translateX(-50%);
}

.tab-header {
    display: flex;
    justify-content: space-around;
    background-color: #f5f5f5;
    /* Background for tabs */
    border-bottom: 2px solid #ccc;
    /* Boundary for tabs */
}

button {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    /* Ensure enough padding for visual impact */
    cursor: pointer;
    /* Pointer cursor to indicate interactivity */
}

button.active {
    background-color: #ffffff;
    /* Background for active tab */
    border-bottom: 2px solid #000;
    /* Distinct boundary for active tab */
}

.tab-content {
    padding: 0px;
    /* Ensure padding for better layout */
    height: calc(100svh - 64px);
    background: rgba(255, 255, 255, 0.8);
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.room-card {
    border-bottom: 2px solid #ccc;
    /* Border for visual separation */
    padding: 5px;
    /* Padding for better readability */
    cursor: pointer;
    /* Indicates clickable */
}

.room-info {
    display: flex;
    /* Align elements horizontally */
    justify-content: space-between;
    /* Ensure even spacing */
    align-items: center;
    /* Align vertically */
}

.room-meta {
    display: flex;
    /* Align meta details horizontally */
    gap: 10px;
    /* Space between elements */
}

.room-creator {
    display: flex;
    /* Align icon and name horizontally */
    align-items: center;
    /* Align vertically */
    gap: 10px;
    /* Space between icon and name */
}

.creator-icon {
    width: 40px;
    /* Set icon size */
    height: 40px;
    /* Set icon size */
    border-radius: 50%;
    /* Make it round */
}

.room-type {
    padding: 5px 10px;
    /* Padding for tag */
    border-radius: 5px;
    /* Rounded corners */
    color: white;
    /* White text for contrast */
}

.tag-ww {
    background-color: #ff5733;
    /* Background color for ワードウルフ */
}

.tag-chat {
    background-color: #3498db;
    /* Background color for 雑談 */
}

.tag-other {
    background-color: #9b59b6;
    /* Background color for その他 */
}

.room-name {
    margin-top: 5px;
    /* Separate room name from previous content */
}

.room-detail {
    margin-top: 5px;
    /* Separate room detail from room name */
}

.created-by {
    max-width: 120px;
    /* Limit width for wrapping */
    white-space: normal;
    /* Allow text wrapping */
    word-break: break-word;
    /* Ensure long words wrap */
}

.logout-button {
    position: absolute;
    /* 絶対位置指定 */
    top: 0px;
    /* 上から10px */
    right: 0%;
    /* 右から10px */
    padding: 5px 10px;
    /* パディング */
    background-color: #f44336;
    /* 赤色 */
    color: white;
    /* 白色の文字 */
    border: none;
    /* ボーダーなし */
    border-radius: 5px;
    /* 角丸 */
    cursor: pointer;
    /* カーソルをポインターに */
    align-self: flex-start;
    height: 32px;
}

.create-room-button {
  background-color: #007BFF; /* Background color for create button */
  color: white; /* Text color */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Indicates clickable */
  position: absolute; /* Position at bottom */
  bottom: 10px;
  right: 50%; /* Center horizontally */
  transform: translateX(50%); /* Adjust to center */
  height: 32px; /* Height */
  padding: 5px 10px; /* Padding */
}
</style>