<template>
  <div class="icon-setting">
    <!-- 現在選択されているアイコンを大きく表示 -->
    <div class="current-icon">
      <img :src="currentIconUrl" alt="Current Icon" />
    </div>
    <!-- アイコン選択エリア -->
    <div class="icon-selector">
      <div v-for="(url, id) in icons" :key="id" class="icon-item">
        <!-- id999は除外 -->
        <img v-if="id != 999" :src="url" @click="selectIcon(id)" alt="Icon" />
      </div>
    </div>
    <!-- ログインに戻るボタン -->
    <button @click="login" class="back-button">戻る</button>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { IconUrlConst } from '../consts/iconsConst';
import { useRouter } from 'vue-router';

export default {
  name: 'IconSetting',
  setup() {
    const store = useStore();
    const router = useRouter();

    // 現在選択されているアイコンのURLを計算
    const currentIconUrl = computed(() => {
      const iconId = store.getters.getIconId;
      return IconUrlConst[iconId] || IconUrlConst[1];  // デフォルトのアイコンを設定
    });

    // アイコンを選択
    const selectIcon = (iconId) => {
      store.dispatch('updateIconId', iconId);
    };

    // 全アイコンのリストを提供
    const icons = IconUrlConst;

    // ログインページに戻る
    const login = () => {
      router.push('/login');  // ログイン画面へのルートを適宜調整してください
    };

    return { currentIconUrl, selectIcon, icons, login };
  }
};
</script>

<style scoped>
.icon-setting {
  position: absolute; /* ログインコンテナを絶対位置指定 */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  top: 45vh;
  left: 50%; /* 左から50%の位置に設定 */
  transform: translate(-50%, -50%); /* 中央に配置 */
}

.current-icon img {
  width: 150px;
  height: 150px;
  margin: 20px;
}

.icon-selector {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  overflow-y: auto;
  max-height: 50vh; /* アイコン表示部の高さ制限 */
  width: 100%;
}

.icon-item img {
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: transform 0.3s;
}

.icon-item img:hover {
  transform: scale(1.1);
}
.back-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
