// Iconの定数を定義する
export const IconUrlConst = {
    1: "/img/icon/1.png",
    2: "/img/icon/2.png",
    3: "/img/icon/3.png",
    4: "/img/icon/4.png",
    5: "/img/icon/5.png",
    6: "/img/icon/6.png",
    7: "/img/icon/7.png",
    8: "/img/icon/8.png",
    9: "/img/icon/9.png",
    10: "/img/icon/10.png",
    11: "/img/icon/11.png",
    12: "/img/icon/12.png",
    13: "/img/icon/13.png",
    14: "/img/icon/14.png",
    15: "/img/icon/15.png",
    16: "/img/icon/16.png",
    17: "/img/icon/17.png",
    18: "/img/icon/18.png",
    19: "/img/icon/19.png",
    20: "/img/icon/20.png",
    21: "/img/icon/21.png",
    22: "/img/icon/22.png",
    23: "/img/icon/23.png",
    24: "/img/icon/24.png",
    25: "/img/icon/25.png",
    26: "/img/icon/26.png",
    27: "/img/icon/27.png",
    28: "/img/icon/28.png",
    29: "/img/icon/29.png",
    30: "/img/icon/30.png",
    31: "/img/icon/31.png",
    32: "/img/icon/32.png",
    33: "/img/icon/33.png",
    34: "/img/icon/34.png",
    35: "/img/icon/35.png",
    36: "/img/icon/36.png",
    37: "/img/icon/37.png",
    38: "/img/icon/38.png",
    39: "/img/icon/39.png",
    40: "/img/icon/40.png",
    41: "/img/icon/41.png",
    42: "/img/icon/42.png",
    43: "/img/icon/43.png",
    44: "/img/icon/44.png",
    45: "/img/icon/45.png",
    46: "/img/icon/46.png",
    47: "/img/icon/47.png",
    48: "/img/icon/48.png",
    49: "/img/icon/49.png",
    50: "/img/icon/50.png",
    51: "/img/icon/51.png",
    52: "/img/icon/52.png",
    53: "/img/icon/53.png",
    54: "/img/icon/54.png",
    55: "/img/icon/55.png",
    56: "/img/icon/56.png",
    57: "/img/icon/57.png",
    58: "/img/icon/58.png",
    59: "/img/icon/59.png",
    60: "/img/icon/60.png",
    61: "/img/icon/61.png",
    62: "/img/icon/62.png",
    63: "/img/icon/63.png",
    999: "/img/icon/999.PNG",
  };