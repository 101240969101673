// roomList.js
import {
    collection,
    query,
    orderBy,
    where,
    onSnapshot,
    Timestamp, 
  } from "firebase/firestore";
  import { dispatch } from "vuex";
const state = {
    rooms: [{
            id: null,
            state: null,
            name: null,
            detail: null,
            type: null,
            created_at: null,
            limit_minute: null,
            limit_member: null,
            wolf_count: null,
            custom_topic: null,
            village_topic: null,
            werewolf_topic: null,
            created_by: null,
            created_by_id: null,
            created_user_icon_id: null,
            is_public: null,
            in_user_count: null,
            startTime: null,
            endTime: null,
      }]
};

// Getters
const getters = {
    getRooms: (state) => state.rooms,
};

// Mutations
const mutations = {
    setRooms: (state, filteredRooms) => {
        state.rooms = filteredRooms;
      }
};

// Actions
const actions = {
    async fetchRooms({ commit }) {
        const now = new Date();
        const oneHourAgo = new Date(now.getTime() - (60 * 60 * 1000)); // 1 hour ago
        const twentyThreeHoursAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000)); // 23 hours ago
        const queryRef = query(
            collection(db, 'rooms'),
            where('is_public', '==', "public"),
            orderBy('created_at', 'desc'),
        );

        onSnapshot(queryRef, (snapshot) => {
            if (snapshot.empty) {
                console.log("No documents matching the query.");
                commit('setRooms', []); // Commit an empty array if no documents
                return;
            }

            const filteredRooms = snapshot.docs.map(doc => {
                const data = doc.data();
                const created_at = data.created_at instanceof Timestamp ? data.created_at.toDate() : new Date(data.created_at);
                const endTime = data.endTime instanceof Timestamp ? data.endTime.toDate() : new Date(data.endTime);
                return { 
                    id: doc.id, 
                    ...data,
                    created_at,
                    endTime
                };
            }).filter(room => {
                if (room.state === 1) {
                    return room.created_at > oneHourAgo && room.in_user_count > 0;
                } else if (room.state === 3) {
                    return room.endTime > twentyThreeHoursAgo;
                }
                return true;
            });

            commit('setRooms', filteredRooms); // Move this line inside the onSnapshot callback
        });
    },
};

// Export the user store module
export default {
    state,
    getters,
    mutations,
    actions
};