// roomDetail.js
// チャットルーム内で使用する情報を管理するストアモジュール
import {
  collection,
  addDoc,
  query,
  orderBy,
  where,
  onSnapshot,
  doc,
  updateDoc,
  Firestore,
  deleteDoc,
  setDoc,
  increment,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { dispatch } from "vuex";
import { format } from 'date-fns';
const state = {
  messages: [],
  lastMessageTimestamp: null, // 最後のメッセージのタイムスタンプを保存するステート
  edcrfvtgboiu: [],
  fontSize: 14,
  roomId: null,
  roomDetail: {
    id: null,
    state: null,
    name: null,
    detail: null,
    type: null,
    created_at: null,
    limit_minute: null,
    limit_member: null,
    wolf_count: null,
    custom_topic: null,
    village_topic: null,
    werewolf_topic: null,
    created_by: null,
    created_by_id: null,
    created_user_icon_id: null,
    is_public: null,
    in_user_count: null,
    startTime: null,
    endTime: null,
    rule: null,
    use_target_message: null,
  },
  // const user = {
//     authorIconId: store.getters.getIconId,
//     authorId: store.getters.getUserId,
//     authorName: store.getters.getUsername,
//     roomUserId: 1,
//     is_owner: true,
//     // 陣営 0:未確定 1:村人 2:人狼
//     team: 0,
//     //　投票先
//     vote: 0,
// };
  users: [{
    authorIconId: null,
    authorId: null,
    authorName: null,
    roomUserId:null,
    is_owner:null,
    team:null,
    vote:null,
    is_wolf:null,
  }],
  unsubscribeRoomDetail: null,
  unsubscribeMessages: null,
  unsubscribeUsers: null,
};
// Getters
const getters = {
  allMessages: (state) => state.messages,
  getedcrfvtgboiu: (state) => state.edcrfvtgboiu,
  getFontSize: (state) => state.fontSize,
  getRoomId: (state) => state.roomId,
  getRoomDetail: (state) => state.roomDetail,
  getUsers: (state) => state.users,
};
// Mutations
const mutations = {
  setMessages: (state, messages) => {
    state.messages = messages;
    // 最後のメッセージのタイムスタンプを更新
    if (messages.length > 0) {
      state.lastMessageTimestamp = messages[messages.length - 1].createdAt;
    }
  },
  setUsers: (state, users) => {
    state.users = users;
  },
  resetLastMessageTimestamp: (state) => {
    state.lastMessageTimestamp = null;
  },
  setEdcrfvtgboiu: (state, edcrfvtgboiu) => {
    state.edcrfvtgboiu = edcrfvtgboiu;
  },
  setFontSize: (state, fontSize) => {
    state.fontSize = fontSize; // Update the font size in the state
  },
  setRoomId: (state, roomId) => {
    state.roomId = roomId;
  },
  setRoomDetail: (state, roomDetail) => {
    state.roomDetail = roomDetail;
  },
  updateLimitMember(state, newLimit) {
    if (newLimit >= state.roomDetail.in_user_count) {
      state.roomDetail.limit_member = newLimit;
    }
  },
  updateWolfCount(state, newWolfCount) {
    state.roomDetail.wolf_count = newWolfCount;
  },
  updateTopics(state, {newVillageTopic, newWerewolfTopic}) {
    state.roomDetail.village_topic = newVillageTopic;
    state.roomDetail.werewolf_topic = newWerewolfTopic;
  },
  setUnsubscribeRoomDetail(state, unsubscribe) {
    state.unsubscribeRoomDetail = unsubscribe;
  },
  setUnsubscribeMessages(state, unsubscribe) {
    state.unsubscribeMessages = unsubscribe;
  },
  setUnsubscribeUsers(state, unsubscribe) {
    state.unsubscribeUsers = unsubscribe;
  }
};
const actions = {
  initMessages: ({ dispatch, commit }) => {
    commit("resetLastMessageTimestamp"); // タイムスタンプをリセット
    dispatch("fetchMessages"); // メッセージの初期読み込みを行う
  },
  // firestoreからedcrfvtgboiuを取得
  fetchEdcrfvtgboiu: ({ commit }) => {
    onSnapshot(collection(db, "edcrfvtgboiu"), (querySnapshot) => {
      const edcrfvtgboiu = [];
      querySnapshot.forEach((doc) => {
        edcrfvtgboiu.push({
          recId: doc.id,
          ...doc.data(),
        });
      });
      commit("setEdcrfvtgboiu", edcrfvtgboiu);
    });
  },

  // firestoreから特定のroomIdに紐づくroomDetailを取得
  fetchRoomDetail: ({ commit,state }) => {
    const roomRef = doc(db, "rooms", state.roomId); // Directly reference the document by ID
    const unsubscribe = onSnapshot(
      roomRef,
      (doc) => {
        if (doc.exists()) {
          commit("setRoomDetail", {
            id: state.roomId,
            ...doc.data(),
          });
        } else {
          console.log("No such document!"); // Handle cases where the document does not exist
        }
      },
      (error) => {
        console.error("Error fetching room details:", error); // Error handling
      }
    );
    commit("setUnsubscribeRoomDetail", unsubscribe);
  },
  fetchMessages: ({ commit, state }) => {
    //現在のURLからroomIdを取得
    let messagesQuery;
    if (state.lastMessageTimestamp) {
      // 最後のメッセージのタイムスタンプ以降のデータのみをクエリする
      messagesQuery = query(
        // firestoreのroomコレクションの中の指定されたroomIDのchatコレクションをクエリする
        collection(db, "rooms", state.roomId, "messages"),
        orderBy("createdAt"),
        where("createdAt", ">", state.lastMessageTimestamp)
      );
    } else {
      // 最初のフェッチではすべてのメッセージを取得
      messagesQuery = query(collection(db, "rooms",state.roomId, "messages"), orderBy("createdAt"));
    }
    const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({
          id: doc.id,
          ...doc.data(),
          //doc.data().createdAtがnullの場合、エラーが発生するため、nullの場合は空文字を返す
          createdAtTime: 
            doc.data().createdAt
              ? format(doc.data().createdAt.toDate(), "HH:mm")
              : "",});
      });
      commit("setMessages", messages);
    });
    commit("setUnsubscribeMessages", unsubscribe);
  },
  saveMessage: async (
    { commit },
    { message, authorId, authorName, authorIconId, isPrivate,recipientId}
  ) => {
    try {
      await addDoc(collection(db, "rooms",state.roomId, "messages"), {
        message: message,
        authorId: authorId,
        authorName: authorName,
        authorIconId: authorIconId,
        createdAt: serverTimestamp(),
        isPrivate: isPrivate,
        recipientId: recipientId,
      });
      // ミューテーションを呼び出す必要はありません。onSnapshotが自動で更新されます。
    } catch (error) {
      console.error("Error adding document: ", error);
    }
    // const prompt = "Write a story about a magic backpack."

    // To generate text output, call generateContent with the text input
    // const result = await model.generateContent(prompt);

    // const response = result.response;
    // const text = response.text();
    // console.log(text);
    // messageが「ことにわ、」で始まる場合、gemini-1.5-flashを呼び出し、返答を取得する
    if (message.startsWith("ことにわ、")) {
        //messageから「ことにわ、」を削除
        const prompt = message.replace("ことにわ、","");
        console.log("prompt", prompt);
        const result = await kotoniwaGenerativeModel.generateContent(prompt);
        const response = result.response;
        const text = response.text();
        console.log("response.text", text);
        // firestoreのmessagesコレクションに返答を保存
        await addDoc(collection(db, "rooms",state.roomId, "messages"), {
          message: text,
          authorId: "system",
          authorName: "ことにわ",
          authorIconId: 999,
          createdAt: serverTimestamp(),
          isPrivate: isPrivate,
          recipientId: recipientId,
        }
      );
    }
  },
  fetchUsers: ({ commit, state }) => {
    let usersQuery;
    usersQuery = query(collection(db, "rooms",state.roomId, "users"), orderBy("roomUserId"));
    const unsubscribe = onSnapshot(usersQuery, (querySnapshot) => {
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      commit("setUsers", users);
    });
    commit("setUnsubscribeUsers", unsubscribe);
  },
  updateFontSize: ({ commit }, fontSize) => {
    commit("setFontSize", fontSize); // Commit the mutation to update the font size
    //localStorageに保存
    localStorage.setItem("fontSize", fontSize);
  },
  updateRoomId: ({ commit }, roomId) => {
    commit("setRoomId", roomId);
  },
  changeLimitMember: async({ dispatch,commit }, newLimit) =>{
    if (newLimit >= state.roomDetail.in_user_count) {
      // Firestore document reference
      const roomRef = doc(db, "rooms", state.roomId);
  
      try {
        // Firestoreに新しいlimit_memberを更新
        await updateDoc(roomRef, {
          limit_member: newLimit
        });
  
        // Vuex stateの更新
        commit('updateLimitMember', newLimit);
        // 部屋人数が変更された旨のシステムメッセージを送信
        dispatch('postSystemMessage', `部屋人数が${newLimit}人に更新されました。`);
  
      } catch (error) {
        console.error("Error updating room limit member:", error);
      }
    } else {
      console.error("New limit is less than the number of current users.");
    }
  },
  changeWolfCount: async({ dispatch,commit }, newWolfCount) =>{
    // Firestore document reference
    const roomRef = doc(db, "rooms", state.roomId);

    try {
      // Firestoreに新しいwolf_countを更新
      await updateDoc(roomRef, {
        wolf_count: newWolfCount
      });

      // Vuex stateの更新
      commit('updateWolfCount', newWolfCount);
      // 狼の数が変更された旨のシステムメッセージを送信
      dispatch('postSystemMessage', `狼の数が${newWolfCount}人に更新されました。`);
    } catch (error) {
      console.error("Error updating wolf count:", error);
    }
  },
  changeTopics: async({ dispatch,commit }, { newVillageTopic, newWerewolfTopic }) =>{
    // Firestore document reference
    const roomRef = doc(db, "rooms", state.roomId);

    try {
      // Firestoreに新しいvillage_topicとwerewolf_topicを更新
      await updateDoc(roomRef, {
        village_topic: newVillageTopic,
        werewolf_topic: newWerewolfTopic
      });

      // Vuex stateの更新
      commit('updateTopics',{ newVillageTopic, newWerewolfTopic });
      // トピックが変更された旨のシステムメッセージを送信
      dispatch('postSystemMessage', `お題が更新されました。`);
    } catch (error) {
      console.error("Error updating topics:", error);
    }
  },
  // システムメッセージを投稿するアクション
  postSystemMessage: async ({ state }, messageText) => {
    const messageData = {
    message: messageText,
    createdAt: serverTimestamp(),
    authorId: "system",
    authorName: "ことにわ",
    authorIconId: 999
  };

  try {
    await addDoc(collection(db, "rooms", state.roomId, "messages"), messageData);
  } catch (error) {
    console.error("Error posting system message:", error);
  }
},
joinRoom: async ({ state, dispatch }, {authorId, authorName, authorIconId}) => {
  const userRef = doc(db, "rooms", state.roomId, "users", authorId); 
  const roomRef = doc(db, "rooms", state.roomId); 
  const user = {
    authorIconId: authorIconId ?? 1,
    authorId: authorId,
    authorName: authorName,
    roomUserId: state.users.length + 1,
    is_owner: false,
    team: 0,
    vote: 0,
  };
  try {
    await setDoc(userRef, user);
    await updateDoc(roomRef, {
      in_user_count: increment(1),
    });
    dispatch("fetchUsers");
    dispatch('postSystemMessage',`${authorName}が入室しました。`);
  } catch (error) {
    console.error("Error joining room:", error);
  }
},
leaveRoom: async ({ state, dispatch }, { authorId ,authorName}) => {
  // FirestoreのusersコレクションからsuthorIdが一致するユーザーを削除
  if(state.roomDetail.state === 1){
  const roomRef = doc(db, "rooms", state.roomId);
  const userRef = doc(db, "rooms", state.roomId, "users", authorId);
    // Firestoreからユーザーを削除
    await deleteDoc(userRef);
    // Firestoreのroomのin_user_countを更新
    await updateDoc(roomRef, {
      in_user_count: increment(-1),
    });
    // ユーザー情報を再取得
    dispatch("fetchUsers");
  }
    dispatch('postSystemMessage',`${authorName}が退室しました。`);
},
updateRoomStateWithTime: async ({ state, dispatch }, { newState, startTime, endTime }) => {
  const roomRef = doc(db, "rooms", state.roomId); 
  try {
    await updateDoc(roomRef, {
      state: newState,
      startTime: startTime,
      endTime: endTime
    });
  } catch (error) {
    console.error("Error updating room state:", error);
  }
},
updateRoomState: async ({ state, dispatch }, { newState}) => {
  const roomRef = doc(db, "rooms", state.roomId); 
  try {
    await updateDoc(roomRef, {
      state: newState
    });
  } catch (error) {
    console.error("Error updating room state:", error);
  }
},
updateWolves: async ({ state, dispatch },  chosenWolves) => {
  const roomRef = doc(db, "rooms", state.roomId);
  try {
    chosenWolves.forEach(async (wolf) => {
      const userRef = doc(db, "rooms", state.roomId, "users", wolf.authorId);
      await updateDoc(userRef, {
        team: 1
      });
    });
  } catch (error) {
    console.error("Error updating wolves:", error);
  }
},
updateVote: async ({ state, dispatch },  {vote,authorId}) => {
  const userRef = doc(db, "rooms", state.roomId, "users", authorId);
  try {
    await updateDoc(userRef, {
      vote: vote
    });
  } catch (error) {
    console.error("Error updating vote:", error);
  }
},
updateEndTime: async ({ state, dispatch },  {endTime}) => {
  const roomRef = doc(db, "rooms", state.roomId); 
  try {
    await updateDoc(roomRef, {
      endTime: endTime
    });
  } catch (error) {
    console.error("Error updating room state:", error);
  }
},
  // Firestoreのリスナーを解除するアクション
  unsubscribeFromFirestore: ({ state, commit }) => {
    // messagesのリスナー解除
    if (state.unsubscribeMessages) {
      state.unsubscribeMessages();
      commit("setUnsubscribeMessages", null);
    }
    // usersのリスナー解除
    if (state.unsubscribeUsers) {
      state.unsubscribeUsers();
      commit("setUnsubscribeUsers", null);
    }
    // roomDetailのリスナー解除
    if (state.unsubscribeRoomDetail) {
      state.unsubscribeRoomDetail();
      commit("setUnsubscribeRoomDetail", null);
    }
  }
};
// Export the user store module
export default {
  state,
  getters,
  mutations,
  actions,
};
