<template>
    <!-- 背景画像をCSSで設定 -->
    <!-- 解像度によって画像が変わるように設定 -->
    <!-- paddingを0にする -->
    <!-- ログイン画面の中央に表示するための要素 -->
    <div class="login-container">
      <h3>-ことば遊ぶお庭-</h3>
      <h1>ことにわ</h1>
      <!-- メインメニューへの遷移ボタン文言は「庭に出る」 -->
      <!-- ボタンをクリックするとメインメニューに遷移 -->
      <h3>Coming Soon</h3>
      <button @click="login" class="login-button">庭に入る(開発中)</button>
      <!-- アイコンを選択するダイアログを表示するためのボタン -->
      <!-- 選択されているアイコン画像を表示 -->
      <!-- 透過背景のカード状のエリアにアイコンと名前を横並びで表示 -->
      <!-- アイコン画像をクリックするとアイコン選択画面に遷移 -->
      <!-- 背景色無しのカードを作成 -->
      <div class="card" style="background-color: transparent; border: none; padding:0px">
        <div class="card-body" style="padding: 0%;">
          <div class="row">
            <div class="col-4 pl-0 pr-0">
            <!-- アイコン画像をクリックするとアイコン選択画面に遷移 -->
              <img :src="iconUrl" class="icon-image" @click="MoveIconSetting" />
            </div>
            <div class="col-8 pl-0" style="padding-left:0%">
            <!-- Vuexのuser.jsとバインディングを行いたい -->
            <!-- 名前の入力欄を表示 -->
            <!-- 親要素内の最下部に配置 -->
            <!-- 親要素内の最下部に配置 -->
            <input v-model="name" type="text" class="form-control" style="width: 180px;"/>
            </div>
          </div>
        </div>
      </div>
      <!-- <img :src="iconUrl" class="icon-image" /> -->
    </div>
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from 'vue';
import { IconUrlConst } from '../consts/iconsConst';

export default {
  setup() {
    //   intitailzie router
    const router = useRouter();
    const store = useStore();
    const iconUrl = computed(() => {
      const iconId = store.getters.getIconId;
      return IconUrlConst[iconId] || '';
    });
    function login() {
        router.push("/room-list");
    }
     // アイコン選択画面に遷移するメソッド
     function MoveIconSetting() {
      router.push('/icon-setting');  // アイコン設定画面へのパスを指定
    }
    // VuexからuserNameを取得し、リアクティブにする
    const name = computed({
      get: () => store.getters.getUsername,
      set: (value) => store.dispatch('updateUsername', value)
    });
    return { login, iconUrl, name,MoveIconSetting};
  }
};
</script>

<style scoped>
h3 {
  font-size: 21px;
  margin: auto;
  font-weight: bold;
}
button {
  margin-top: 50px;
  border-radius: 8px;
  padding: 15px;
  font-weight: bold;
  border: 1px solid #e5e8e8;
  cursor: pointer;
}
button:hover {
  background: #151339;
  color: #e5e8e8;
}
.icon-image {
  width: 100px; /* 幅を100pxに設定 */
  position: center; /* 画像が常に中央に表示されるように設定 */
}
.login-container {
  position: absolute; /* ログインコンテナを絶対位置指定 */
  top: 45%;  /* 上から50%の位置に設定 */
  left: 50%; /* 左から50%の位置に設定 */
  transform: translate(-50%, -50%); /* 中央に配置 */
  width: 300px; /* 幅を300pxに設定 */
  text-align: center; /* テキストを中央揃えに設定 */
  display: flex; /* Flexboxを有効化 */
  flex-direction: column; /* 子要素を縦方向に配置 */
  justify-content: space-between; /* 間隔を均等に設定 */
  height: 30vh; /* コンテナの高さを設定 */
}
.card {
  display: flex; /* Flexboxを有効化 */
  flex-direction: column; /* 子要素を縦方向に配置 */
  justify-content: space-between; /* 要素間の間隔を最大にする */
  height: 100%; /* カードの高さを親に合わせる */
}
.login-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>